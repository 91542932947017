/**
 * Represents a user for analytics purposes.
 *
 * @interface AnalyticsUser
 * @property {string} id - The unique identifier for the user.
 * @property {string} [email] - The user's email address (optional).
 * @property {string} [name] - The user's name (optional).
 * @property {string} [avatar_url] - The URL of the user's avatar image (optional).
 * @property {string[]} [groups] - An array of group names the user belongs to (optional).
 */
export interface AnalyticsUser {
  id: string;
  email?: string | null;
  name?: string | null;
  avatar_url?: string | null;
  groups?: string[] | null;
}

/**
 * Represents an analytics provider interface.
 * This abstracts the underlying analytics third party library, for more flexibility when swapping providers.
 *
 * @interface AnalyticsProvider
 * @property {function} track - Tracks an event with optional properties.
 * @property {function} identify - Identifies a user for analytics purposes.
 * @property {function} initialize - Initializes the analytics provider.
 */
export interface AnalyticsProvider {
  /**
   * Tracks an event with optional properties.
   * @param {string} event - The name of the event to track.
   * @param {Record<string, unknown>} [properties] - Optional properties associated with the event.
   * @returns {Promise<void>}
   */
  track: (event: string, properties?: Record<string, unknown>) => Promise<void>;

  /**
   * Identifies a user for analytics purposes.
   * @param {AnalyticsUser | null} user - The user to identify, or null to clear the current user.
   * @returns {void}
   */
  identify: (user: AnalyticsUser | null) => void;

  /**
   * Initializes the analytics provider.
   * @returns {Promise<void>}
   */
  initialize: () => Promise<void>;
}

/**
 * Enum representing various analytics events tracked in the application.
 *
 * These events cover a wide range of user actions and system events, including:
 * - User authentication (login, logout, signup)
 * - Content creation and management (creating documents, comments, spaces, tags)
 * - Integration actions (connecting and managing third-party accounts)
 * - User interactions (viewing documents, searching, sharing)
 * - Application installation events
 * - Error tracking
 *
 * Each enum value represents a specific event that can be tracked for analytics purposes.
 *
 * @enum {string}
 */
export enum AnalyticsEvents {
  DashboardVisit = 'Dashboard Visit',

  EmailLogin = 'Email Login',
  OAuthLogin = 'OAuth Login',
  Logout = 'Logout',
  Signup = 'Signup',
  SignupConfirm = 'Email Signup Confirm',

  CreatedFdoc = 'Created Fdoc',
  CreatedComment = 'Created Comment',
  CreatedSpace = 'Created Space',

  CreatedTag = 'Created Tag',
  RenamedTag = 'Renamed Tag',
  AssignTag = 'Assign Tag',
  UnassignTag = 'Unassign Tag',

  ClickedAddGoogleAccount = 'Integration: Clicked Add Google Account',
  ConfirmedAddGoogleAccount = 'Integration: Confirmed Add Google Account',
  ConnectedGoogleDrive = 'Integration: Connected Google Drive',
  DeletedGoogleDrive = 'Integration: Deleted Google Drive',

  ClickedAddGithubAccount = 'Integration: Clicked Add Github Account',
  DeletedGithubAccount = 'Integration: Deleted Github Account',

  RegisteriOSDevice = 'Integration: Register iOS Screenshots Device',
  PausediOSDevice = 'Integration: Paused iOS Screenshots Device',

  PausedConnection = 'Integration: Paused Connection',
  ResumedConnection = 'Integration: Resumed Connection',

  MigratedFromOtherApp = 'Migrated From Other App',

  FirstArrival = 'First Arrival',

  DeletedResource = 'Deleted Resource',
  DeletedComment = 'Deleted Comment',
  DeletedSpace = 'Deleted Space',
  LeftSpace = 'Left Space',
  DeletedTag = 'Deleted Tag',

  EditedComment = 'Edited Comment',
  RenamedSpace = 'Renamed Space',
  RenamedResource = 'Renamed Resource',

  MovedItems = 'Moved Items',

  ViewFdoc = 'View Fdoc',
  OpenSpace = 'Open Space',
  OpenFolder = 'Open Folder',
  OpenTag = 'Open Tag',
  OpenIntegration = 'Open Integration',

  CopiedShareLink = 'Copied Share Link',
  VisitedSharedLink = 'Visited Shared Link',
  JoinedCollection = 'Joined Collection',
  SharedSpace = 'Shared Space',
  UnsharedSpace = 'Unshared Space',
  SharedResource = 'Shared Resource',
  UnsharedResource = 'Unshared Resource',

  ChangeLabel = 'Change Label',
  RemovedLabel = 'Removed Label',
  RenameLabel = 'Rename Label',

  InstalledDesktop = 'Installed Desktop',
  InstallediOS = 'Installed iOS',
  InstalledAndroid = 'Installed Android',

  Search = 'Search',

  NotepadEdit = 'Notepad Edit',

  VotedForDataConnection = 'Voted For Data Connection',

  ClickedUpgrade = 'Clicked Upgrade',

  ClickedManagePlan = 'Clicked Manage Plan',

  ChatbotDocumentChatMessage = 'Assistant Document Chat Message',
  ChatbotSearchChatMessage = 'Assistant Search Chat Message',
  ChatbotChatMessage = 'Assistant Chat Message',
  ChatbotQuotaLimitHit = 'Assistant Quota Limit Hit',

  Error = 'Error',

  MagicRename = 'Magic Rename',

  ClickUpgradeFromHome = 'Clicked Upgrade from Home',
}
