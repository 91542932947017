import { desktopFloatUrls, isInDesktop } from '@/src/hooks/todesktop';

import { TooltipProvider } from '@radix-ui/react-tooltip';
import { platform } from '@todesktop/client-core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import gsap from 'gsap';
import CustomEase from 'gsap/dist/CustomEase';
import { enableMapSet } from 'immer';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { SWRConfig } from 'swr';
import { shallow } from 'zustand/shallow';
import useAuthStore, { useAuthInitializer } from '../hooks/auth';
import { GlobalSearchProvider } from '../hooks/hybridsearch';
import { ResponsiveProvider } from '../hooks/responsive';
import { updateTrackerUser } from '../lib/or';
import { useWoodyInitializer } from '../services/woody/woody';
import { NextPageWithAuth } from '../types/page';
import AlertInterface from './AlertInterface/AlertInterface';
import App from './App';
import DesktopBar from './DesktopBar/DesktopBar';

import { useHideSplashScreen } from '@/src/lib/capacitor/useHideSplashScreen';
import { useSentryInit } from '@/src/lib/sentry/useSentryInit';
import { useAnalyticsInitializer } from '@/src/modules/analytics/hooks/useAnalyticsInitializer';
import { IconsStylesheet } from '@/src/modules/icons/IconsStylesheet';
import OpenInNativeAppDrawer from '@/src/modules/mobile/components/OpenInNativeAppDrawer';
import { useColorSchemeToggleKbd } from '@/src/modules/ui/theme/useColorSchemeToggleKbd';
import { ApiUserMe } from '@/src/modules/user/user.types';
import { PageOnLoadAnimationContainer } from '../core/shared/PageOnLoadAnimationContainer';
import { useAndroidInsets } from '../hooks/useAndroidInsets';
import { QueryClientProvider } from '../lib/react-query/ReactQueryClientProvider';
import { StyledComponentsConfig } from '../lib/styled-components/StyledComponentsConfig';
import { AppThemeStylesheet } from '../modules/ui/theme/variables';
import { SocketIOProvider } from '../lib/socket.io/SocketIOProvider';

interface Props extends AppProps {
  pageProps: {
    origin: string | undefined;
    referer: string | undefined;

    title: string;
    image: string;
    description: string;
    url: string;
    bigCard: boolean;
  };

  Component: NextPageWithAuth;
}

const FabricApp: React.FC<Props> = ({ Component, pageProps }) => {
  useAuthInitializer(Component.authRequirements ?? { requiresAuth: false });
  useWoodyInitializer();
  useAnalyticsInitializer();
  useSentryInit();

  const router = useRouter();
  enableMapSet();

  useHideSplashScreen();

  /**
   * keyboard shortcut for switching the theme
   */
  useColorSchemeToggleKbd();

  const { AndroidGlobalStyles } = useAndroidInsets(Component.rawPage);

  const WrappingComponent = Component.WrappingComponent ?? React.Fragment;
  const wrappingComponentProps = Component.wrappingComponentProps;

  const onAuthEvent = useAuthStore((state) => state.on, shallow);

  useEffect(() => {
    const unsubscribers: (() => void)[] = [];

    unsubscribers.push(
      onAuthEvent('login', (user) => {
        updateTrackerUser(user);
      }),
      onAuthEvent('user', (user: ApiUserMe | null) => {
        if (!user) return;

        updateTrackerUser(user);
      }),
      onAuthEvent('status', ({ user }) => {
        if (!user) return;

        updateTrackerUser(user);
      }),
    );

    return () => {
      unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
  }, [onAuthEvent, router]);

  useEffect(() => {
    gsap.registerPlugin(CustomEase);
    dayjs.extend(relativeTime);
    dayjs.extend(advancedFormat);
    dayjs.extend(duration);
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf/pdf.worker.js';
  }, []);

  const [showDesktopBar, setShowDesktopBar] = useState(false);

  useEffect(() => {
    setShowDesktopBar(
      isInDesktop() &&
        platform.os.getOSType() === 'Darwin' &&
        !desktopFloatUrls.includes(router.pathname),
    );
  }, [router.pathname]);

  useEffect(() => {
    if (!showDesktopBar) return;

    document.documentElement.style.setProperty(
      '--safe-margin-offset-top',
      `calc(env(safe-area-inset-top) + 25px)`,
    );

    // weirdy enough, on desktop app while display: fixed or other issue, maybe in root body, the env safe area offset doesn't work
    // it doesn't work on any child nodes either
    document.documentElement.style.setProperty('--safe-margin-offset-desktop', `25px`);

    return () => {
      document.documentElement.style.setProperty(
        '--safe-margin-offset-top',
        'env(safe-area-inset-top)',
      );
      document.documentElement.style.setProperty('--safe-margin-offset-desktop', `0px`);
    };
  }, [showDesktopBar]);

  return (
    <StyledComponentsConfig forcedScheme={Component.rawPage ? 'system' : undefined}>
      <AppThemeStylesheet />
      <IconsStylesheet />
      {AndroidGlobalStyles}
      <QueryClientProvider>
        <SWRConfig>
          <TooltipProvider>
            <ResponsiveProvider>
              {Component.rawPage ? (
                <SocketIOProvider>
                  <AlertInterface />
                  <Component />
                </SocketIOProvider>
              ) : (
                <GlobalSearchProvider>
                  <App>
                    {showDesktopBar && <DesktopBar />}
                    <AlertInterface />
                    <OpenInNativeAppDrawer />

                    <WrappingComponent {...wrappingComponentProps}>
                      <PageOnLoadAnimationContainer>
                        <Component
                          {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ...(pageProps as unknown as any)
                          }
                        />
                      </PageOnLoadAnimationContainer>
                    </WrappingComponent>
                  </App>
                </GlobalSearchProvider>
              )}
            </ResponsiveProvider>
          </TooltipProvider>
        </SWRConfig>
      </QueryClientProvider>
    </StyledComponentsConfig>
  );
};

export default FabricApp;
