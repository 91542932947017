import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { captureException, withScope } from '@sentry/nextjs';
import isUrl from 'is-url';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { MOBILE_AUTH_DEEPLINK } from '../lib/mobile/oauth';
import { REDIRECTOR_URL } from '../services/woody/woody';
import { toast } from '../store/alerts';
import { Intent, SendIntent } from '@/mobile/SendIntent';

const ANDROID_MOBILE_DEEPLINK = 'fabric-android-1jhpcmuj27';

export type ShareIntentResult = {
  description: string;
  title: string;
  url: string;
  type: string;
};

const intentToShareIntents = (intent: Intent): ShareIntentResult[] => {
  // the intent contains an additionalItems property that can contain other intents,
  // so we make sure to always return an array of intents
  const intents = Array.isArray(intent.additionalItems)
    ? [intent, ...intent.additionalItems]
    : [intent];

  return intents.map((intent) => ({
    description: intent.description ? decodeURIComponent(intent.description) : '',
    title: intent.title ? decodeURIComponent(intent.title) : '',
    url: intent.url ? decodeURIComponent(intent.url) : '',
    type: intent.type ? decodeURIComponent(intent.type) : '',
  }));
};

interface MobileStore {
  sharedItems: ShareIntentResult[];
  setSharedItems: (func: (items: ShareIntentResult[]) => ShareIntentResult[]) => void;
}

export const mobileStore = create<MobileStore>((set, get) => ({
  sharedItems: [],
  setSharedItems: (items) =>
    set({
      sharedItems: items(get().sharedItems),
    }),
}));

export const useShareIntentSetup = () => {
  const { setSharedItems } = mobileStore(
    (state) => ({
      setSharedItems: state.setSharedItems,
    }),
    shallow,
  );
  useEffect(() => {
    if (!isInMobile() || !Capacitor.isPluginAvailable('SendIntent')) return;

    const listenToIntent = () => {
      SendIntent.checkSendIntentReceived()
        .then((result: Intent | null) => {
          if (!result) return;
          const shareIntents = intentToShareIntents(result);

          if (isInMobile('ios') || !Capacitor.isPluginAvailable('AppLauncher')) {
            setSharedItems((items) => [...items, ...shareIntents]);
            return;
          }

          // Android we will send the intent to the MainActivity
          // so we will send the intents via a deeplink:
          // ANDROID_MOBILE_DEEPLINK://intents?title[0]=...&description[0]=...&url[0]=...&type[0]=...
          const url = new URL(`${ANDROID_MOBILE_DEEPLINK}://intents`);
          shareIntents.forEach((intent, index) => {
            url.searchParams.set(`title[${index}]`, intent.title);
            url.searchParams.set(`description[${index}]`, intent.description);
            url.searchParams.set(`url[${index}]`, intent.url);
            url.searchParams.set(`type[${index}]`, intent.type);
          });
          url.searchParams.set('count', shareIntents.length.toString());

          AppLauncher.openUrl({ url: url.toString() });
          SendIntent.finish(); // this closes the activity
        })
        .catch((err: unknown) => {
          console.log('Error receiving intent', err);
        });
    };

    window.addEventListener('sendIntentReceived', listenToIntent);
    listenToIntent();
    return () => {
      window.removeEventListener('sendIntentReceived', listenToIntent);
    };
  }, [setSharedItems]);
};

export const isInMobile = (platform?: 'ios' | 'android') => {
  return platform ? platform === Capacitor.getPlatform() : Capacitor.getPlatform() !== 'web';
};

const androidStaticAddToIntents = (deeplink: string) => {
  if (!deeplink.startsWith(`${ANDROID_MOBILE_DEEPLINK}://intents`) || !isInMobile('android'))
    return;

  const url = new URL(deeplink);
  const count = url.searchParams.get('count');
  if (!count) return;

  const intents: ShareIntentResult[] = [];
  for (let i = 0; i < parseInt(count); i++) {
    const title = url.searchParams.get(`title[${i}]`) ?? '';
    const description = url.searchParams.get(`description[${i}]`) ?? '';
    const urlParam = url.searchParams.get(`url[${i}]`) ?? '';
    const type = url.searchParams.get(`type[${i}]`) ?? '';

    intents.push({
      // Title is used in iOS for text being shared, as such we will append the URL param to the title
      // in case it's not a URL
      title: isUrl(urlParam) ? title : `${title.length > 0 ? `${title}\n` : ''}${urlParam}`,
      description,
      url: isUrl(urlParam) ? urlParam : '',
      type,
    });
  }

  // handle plain text better on android
  //

  mobileStore.getState().setSharedItems((items) => [...items, ...intents]);
};

const tryHandleUniversalLink = (router: NextRouter, url: string) => {
  try {
    const redirector = new URL(REDIRECTOR_URL);
    // if url doesn't matches current location we return false
    const urlObj = new URL(url);
    if (urlObj.hostname !== window.location.hostname && urlObj.hostname !== redirector.hostname)
      return false;

    // now we redirect to the path
    const path = urlObj.pathname;
    const rawQuery = urlObj.searchParams;

    const query: Record<string, string> = {};
    for (const [key, value] of rawQuery) {
      query[key] = value;
    }

    router.push({
      pathname: path,
      query,
    });

    return true;
  } catch (e) {
    return false;
  }
};

const tryHandleAuthDeeplink = (router: NextRouter, url: string, usedCodes?: string[]) => {
  if (!url.toLocaleLowerCase().startsWith(MOBILE_AUTH_DEEPLINK.toLocaleLowerCase())) return false;
  if (isInMobile('ios')) Browser.close(); // close in-app browser

  try {
    const urlObj = new URL(url);
    const code = urlObj.searchParams.get('code');

    if (!code || typeof code !== 'string') return false;

    if (usedCodes?.includes(code)) return false;

    router.push(`/oauth?code=${code}&mobile=true`);

    return code;
  } catch (e) {
    toast({
      content: 'An error occurred while trying to authenticate you. Please try again.',
    });

    withScope((scope) => {
      scope.setLevel('fatal');
      scope.setTag('error', 'auth-deeplink');
      captureException(e);
    });

    return false;
  }
};

export const useMobileAppListeners = () => {
  const router = useRouter();
  // Used codes go into this array to avoid recalling the oauth callback twice for the same code
  // Especially in iOS there are 2 different events that cause appUrlOpen to be called it seems
  const usedCodesRef = useRef<string[]>([]);

  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const { url: strUrl } = data;

      androidStaticAddToIntents(strUrl);

      const code = tryHandleAuthDeeplink(router, strUrl, usedCodesRef.current);
      if (code) {
        usedCodesRef.current.push(code);
        return;
      }

      if (tryHandleUniversalLink(router, strUrl)) return;
    });

    return () => {
      App.removeAllListeners();
    };
  }, [router, router.push]);
};
