import { AppColorScheme, useColorScheme } from '@/src/modules/ui/theme/useColorScheme';
import isPropValid from '@emotion/is-prop-valid';
import { PropsWithChildren } from 'react';
import { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';

/**
 * This was made automatically in styled-components as of v5,
 * now we need to do it manually
 */
const shouldForwardProp = (prop: string, element: WebTarget) => {
  const isNativeHTMLElement = typeof element === 'string';

  return isNativeHTMLElement ? isPropValid(prop) : true;
};

declare module 'styled-components' {
  // eslint-disable-next-line import/no-unused-modules
  export interface DefaultTheme {
    userColorScheme: AppColorScheme;
  }
}

interface StyledComponentsConfigProps extends PropsWithChildren {
  forcedScheme?: AppColorScheme;
}

/**
 * shouldForwardProp cleans up passing props to the dom
 * this is a global setting
 * otherwise we would need to use transient props
 * https://styled-components.com/docs/api#shouldforwardprop
 */
export const StyledComponentsConfig = ({ children, forcedScheme }: StyledComponentsConfigProps) => {
  const { colorScheme } = useColorScheme();
  return (
    <ThemeProvider
      theme={{
        userColorScheme: forcedScheme ?? colorScheme,
      }}
    >
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>{children}</StyleSheetManager>
    </ThemeProvider>
  );
};
