import { useKeyboardMapStore } from '@/src/modules/keyboardShortcuts/hooks/useKeyboardMap';
import { keyToDisplayValue } from '@/src/modules/keyboardShortcuts/modifierKeys';
import { UserKeyboardShortcutsDesktop } from '@/src/modules/user/user.types';
import { hasMacOSKbd } from '@/src/types/global';
import { useMemo } from 'react';

const isMac = hasMacOSKbd();

/**
 * returns a map fn if navigator.keyboard.getLayoutMap() is available
 */
const useLayoutToPhysicalMapper = () => {
  const keyboardMap = useKeyboardMapStore((state) => state.keyToCodeMap);

  return useMemo(
    () =>
      !!keyboardMap
        ? (keys: string[]) => {
            return keys.map((key) => {
              return (keyboardMap.get(key.toLowerCase()) || key).replace(/Key|Digit/g, '');
            });
          }
        : undefined,
    [keyboardMap],
  );
};

const defaultAccelerators: Record<keyof UserKeyboardShortcutsDesktop, string[]> = {
  quickSearch: isMac ? ['Meta', 'Alt', 'F'] : ['Control', 'Alt', 'F'],
  quickNote: isMac ? ['Meta', 'Alt', 'N'] : ['Control', 'Alt', 'N'],
};

type DefaultAccelerators = Record<
  keyof UserKeyboardShortcutsDesktop,
  {
    displayStr: string;
    accelerator: string;
  }
>;

export const useDefaultAccelerators = (): DefaultAccelerators => {
  const mapper = useLayoutToPhysicalMapper();

  const res: DefaultAccelerators = useMemo(
    () =>
      Object.entries(defaultAccelerators).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: {
            displayStr: value.map((key) => keyToDisplayValue(key)).join(' + '),
            accelerator: (mapper ? mapper(value) : value).join('+'),
          },
        };
      }, {} as DefaultAccelerators),
    [mapper],
  );

  return res;
};
