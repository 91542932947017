import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { StoredDataTransfer } from '@/src/lib/clipboard';
import { logTrackerEvent, TrackerEventKind } from '@/src/lib/or';
import { isDefined } from '@/src/lib/utils';
import { ExtraPanelTab } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { ResourceFilterType } from '@/src/modules/resources/contants/resourceType';
import { ApiUserMe } from '@/src/modules/user/user.types';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { SIDEBAR_OPTIONS } from '../components/DashboardSidebarV2/constants';
import { OfViewMode } from '../components/ViewModeSwitcher/ViewModeSwitcher';
import { omit, pick } from '../lib/store';
import { ZustandDynamicCaller } from '../types/store';

export type NewModalOptions = {
  type?: NewResourceType;

  // instead of specifying a type and it's needed properties we will make all other properties optional,
  // this way if the user is deciding what type of fdoc to create it won't clear the other properties for
  // the other types of fdocs
  url?: string;
  comment?: string;
  shared?: boolean;
  urlTitle?: string;

  text?: string;
  dataTransfer?: StoredDataTransfer;
};

interface GlobalSelectionOptions {
  selectedFdocsIds?: string[];
  postAdd?: boolean;
  clearOnClose?: boolean;
}

export type SpaceOverviewViewMode = 'Grid' | 'List';

interface UIStore {
  userLastFetch: number;

  requiredUpdateModalOpen: boolean;
  setRequiredUpdateModalOpen: (open: boolean) => void;

  mobileKeyboardHeight: number;
  setMobileKeyboardHeight: (height: number) => void;
  keyboardOpen: boolean;
  setKeyboardOpen: (open: boolean) => void;
  mobileKeyboardHeightSupported: boolean;
  setMobileKeyboardHeightSupported: (open: boolean) => void;

  supportsPassiveEvents: boolean;

  searchQuery: string;
  filter: ResourceFilterType;
  filterRoute: string;
  viewMode: OfViewMode;
  viewModeInSpace: OfViewMode;
  spaceOverviewViewMode: SpaceOverviewViewMode;
  zoomLevel: number;
  sidebarOpen: boolean;
  sidebarExpanded: boolean;
  sidebarPreferredWidth: number;
  shareGuideShown: boolean;
  isNewModalOpen: boolean;
  newModalOptions: NewModalOptions;
  expandedFdocId: string | null;
  expandedFdocPath: string | null;
  expandedFdocSelector: string | null;
  globalSelectionOptions: GlobalSelectionOptions;
  isSearchOverlayOpen: boolean;
  isExperimentSearchOverlayOpen: boolean;

  lastFdocUpdate: number | null;

  // WEB/DESKTOP ONLY
  expandedFdocSidebarOpen: boolean;
  // Init tab serves to know if the UI should animate stuff in, since if the tab was init
  // we assume there was already a visualization active and re-animating is non-sensical
  expandedFdocSidebarInitTab?: ExtraPanelTab;
  expandedFdocSidebarTab: ExtraPanelTab;
  setExpandedFdocSidebarOpen: (open: boolean) => void;
  setExpandedFdocSidebarTab: (tab: ExtraPanelTab) => void;

  setSupportsPassiveEvents: (supports: boolean) => void;

  setUserLastFetch: (timestamp: number) => void;
  setSearchQuery: (query: string) => void;
  setFilter: (filter: ResourceFilterType) => void;
  setFilterRoute: (filter: string) => void;
  setViewMode: (mode: OfViewMode) => void;
  setViewModeInSpace: (mode: OfViewMode) => void;
  setSpaceOverviewViewMode: (mode: SpaceOverviewViewMode) => void;
  setZoomLevel: (level: number) => void;
  setSidebarOpen: (open: boolean) => void;
  // WEB/DESKTOP ONLY
  setSidebarExpanded: (open: boolean) => void;
  setSidebarPreferredWidth: (newValue: number) => void;
  // WEB/DESKTOP ONLY
  setShareGuideShown: (shown: boolean) => void;
  setNewModalOpen: (open: boolean, newModalOptions?: NewModalOptions) => void;
  setExpandedFdocId: (id: string | null, tab?: ExtraPanelTab) => void;
  setExpandedFdocPath: (path: string | null) => void;
  setNewModalOptions: ZustandDynamicCaller<NewModalOptions>;
  setGlobalSelectionOptions: (options: GlobalSelectionOptions) => void;
  setLastFdocUpdate: (timestamp: number) => void;
  setIsSearchOverlayOpen: (newState: boolean) => void;
  setIsExperimentSearchOverlayOpen: (newState: boolean) => void;
  setExpandedFdocSelector: (selector: string | null) => void;

  reset: () => void;
}

const emptyNewModalOptions: NewModalOptions = {};

const useUIStore = create<UIStore>()(
  persist(
    (set) => ({
      requiredUpdateModalOpen: false,
      setRequiredUpdateModalOpen: (open: boolean) => set({ requiredUpdateModalOpen: open }),

      user: null as ApiUserMe | null,
      userLastFetch: 0,
      searchQuery: '',
      filter: 'All' as ResourceFilterType,
      filterRoute: '/saved',
      viewMode: 'Grid' as OfViewMode,
      viewModeInSpace: 'Grid' as OfViewMode,
      spaceOverviewViewMode: 'Grid' as SpaceOverviewViewMode,
      zoomLevel: 0.9,
      sidebarOpen: false,
      sidebarExpanded: false,
      sidebarPreferredWidth: Number(SIDEBAR_OPTIONS.EXPANDED_WIDTH.RECOMMENDED),
      shareGuideShown: false,
      isNewModalOpen: false,
      newModalOptions: emptyNewModalOptions,
      expandedFdocId: null,
      expandedFdocPath: null,
      globalSelectionOptions: {},
      playableTimestamp: null,
      pdfPage: null,
      mobileKeyboardHeight: 0,
      keyboardOpen: false,
      supportsPassiveEvents: false,
      expandedFdocSelector: null,

      expandedFdocSidebarInitTab: 'comments',
      expandedFdocSidebarTab: 'comments',
      expandedFdocSidebarOpen: true,
      chatAssistantOpen: false,
      chatAssistantFullscreen: false,
      isSearchOverlayOpen: false,
      isExperimentSearchOverlayOpen: false,
      mobileKeyboardHeightSupported: false,

      lastFdocUpdate: null,

      setSupportsPassiveEvents: (supports: boolean) => set({ supportsPassiveEvents: supports }),

      setUserLastFetch: (timestamp) => set({ userLastFetch: timestamp }),
      setSearchQuery: (query: string) => set({ searchQuery: query }),
      setFilter: (filter: ResourceFilterType) => set({ filter }),
      setFilterRoute: (filter: string) => set({ filterRoute: filter }),
      setViewMode: (mode: OfViewMode) => set({ viewMode: mode }),
      setViewModeInSpace: (mode: OfViewMode) => set({ viewModeInSpace: mode }),
      setSpaceOverviewViewMode: (mode: SpaceOverviewViewMode) =>
        set({ spaceOverviewViewMode: mode }),
      setZoomLevel: (level: number) => set({ zoomLevel: level }),
      setSidebarOpen: (open: boolean) => set({ sidebarOpen: open }),
      setSidebarExpanded: (open: boolean) => set({ sidebarExpanded: open }),
      setSidebarPreferredWidth: (newValue: number) => set({ sidebarPreferredWidth: newValue }),
      setShareGuideShown: (shown: boolean) => set({ shareGuideShown: shown }),
      setNewModalOpen: (open: boolean, newModalOptions?: NewModalOptions) => {
        return set((state) => {
          if (state.isNewModalOpen === false && open) {
            logTrackerEvent({
              kind: TrackerEventKind.OPEN_NEW_FDOC_MODAL,
              payload: {
                type: newModalOptions?.type ?? null,
                sharedFromMobile: !!newModalOptions?.shared,
              },
            });
          }
          return {
            isNewModalOpen: open,
            newModalOptions: newModalOptions ?? emptyNewModalOptions,
          };
        });
      },
      setExpandedFdocId: (id: string | null, tab?: ExtraPanelTab) =>
        set((s) => ({
          expandedFdocId: id,
          expandedFdocSidebarTab: tab ?? s.expandedFdocSidebarTab,
          expandedFdocSidebarInitTab: tab ?? undefined,
          expandedFdocSidebarOpen: isDefined(tab) ? true : s.expandedFdocSidebarOpen,
        })),
      setExpandedFdocPath: (path: string | null) => set({ expandedFdocPath: path }),
      setExpandedFdocSelector: (selector: string | null) => set({ expandedFdocSelector: selector }),
      setNewModalOptions: (options) => {
        if (typeof options === 'function') {
          set((state) => ({ newModalOptions: options(state.newModalOptions) }));
          return;
        }

        set({ newModalOptions: options });
      },
      setLastFdocUpdate: (timestamp: number) => set({ lastFdocUpdate: timestamp }),
      setIsSearchOverlayOpen: (newState) => set({ isSearchOverlayOpen: newState }),
      setIsExperimentSearchOverlayOpen: (newState) =>
        set({ isExperimentSearchOverlayOpen: newState }),
      setGlobalSelectionOptions: (options: GlobalSelectionOptions) =>
        set({ globalSelectionOptions: options }),
      setMobileKeyboardHeight: (height: number) => set({ mobileKeyboardHeight: height }),
      setMobileKeyboardHeightSupported: (value: boolean) =>
        set({ mobileKeyboardHeightSupported: value }),
      setKeyboardOpen: (open: boolean) => set({ keyboardOpen: open }),
      setExpandedFdocSidebarOpen: (open: boolean) => set({ expandedFdocSidebarOpen: open }),
      setExpandedFdocSidebarTab: (tab: ExtraPanelTab) =>
        set({ expandedFdocSidebarTab: tab, expandedFdocSidebarInitTab: undefined }),

      reset: () => set({ searchQuery: '', filter: 'All', viewMode: 'Grid' }),
    }),
    {
      name: 'UIStore',
      version: 3.1,
      partialize: (state) => {
        // dont persist searchQuery
        const rest = omit(state, [
          'searchQuery',
          'sidebarOpen',
          'newModalOptions',
          'isNewModalOpen',
          'globalSelectionOptions',
          'expandedFdocId',
          'supportsPassiveEvents',
          'isSearchOverlayOpen',
          'requiredUpdateModalOpen',
          'isExperimentSearchOverlayOpen',
          'expandedFdocSidebarTab',
          'expandedFdocSidebarInitTab',
          'mobileKeyboardHeight',
        ]);

        return rest;
      },
      migrate(persistedState: unknown, version) {
        const state = persistedState as UIStore;

        if (version < 3.1) {
          return omit(state, ['sortMode' as keyof UIStore]) as UIStore;
        }

        // make sure to update the sortMode
        return {
          ...state,
        };
      },
    },
  ),
);

export const setExpandedFdocId = (id: string | null, tab?: ExtraPanelTab) =>
  useUIStore.getState().setExpandedFdocId(id, tab);
export const setNewModalOpen = (open: boolean, newModalOptions?: NewModalOptions) =>
  useUIStore.getState().setNewModalOpen(open, newModalOptions);
export const setGlobalSelectionOptions = (options: GlobalSelectionOptions) =>
  useUIStore.getState().setGlobalSelectionOptions(options);

export const useSearchOverlay = () => {
  const router = useRouter();
  const { isSearchOverlayOpen, setIsSearchOverlayOpen } = useUIStore(
    (s) => pick(s, ['isSearchOverlayOpen', 'setIsSearchOverlayOpen']),
    shallow,
  );

  return {
    isOverlayOpen: isSearchOverlayOpen,
    openOverlay: useCallback(
      (type: 'global' | 'location') => {
        setIsSearchOverlayOpen(true);
        router.push({
          pathname: router.pathname,
          query: { ...omit(router.query, ['openSearch']), search: type },
        });
      },
      [router, setIsSearchOverlayOpen],
    ),
    closeOverlay: useCallback(() => {
      const { search, ...query } = router.query;
      setIsSearchOverlayOpen(false);

      // we remove global search from history if it's there
      if (search) {
        router.replace({ pathname: router.pathname, query });
      }
    }, [router, setIsSearchOverlayOpen]),
  };
};

export const useExperimentSearchOverlay = () => {
  const router = useRouter();
  const {
    isExperimentSearchOverlayOpen: isHybridSearchOverlayOpen,
    setIsExperimentSearchOverlayOpen: setIsHybridSearchOverlayOpen,
  } = useUIStore(
    (s) => pick(s, ['isExperimentSearchOverlayOpen', 'setIsExperimentSearchOverlayOpen']),
    shallow,
  );

  return {
    isOverlayOpen: isHybridSearchOverlayOpen,
    openOverlay: useCallback(() => {
      setIsHybridSearchOverlayOpen(true);
      router.push({
        pathname: router.pathname,
        query: { ...router.query, experimentSearch: 'global' },
      });
    }, [router, setIsHybridSearchOverlayOpen]),
    closeOverlay: useCallback(() => {
      const { experimentSearch, ...query } = router.query;
      setIsHybridSearchOverlayOpen(false);

      // we remove hybrid search from history if it's there
      if (experimentSearch) {
        router.replace({ pathname: router.pathname, query });
      }
    }, [router, setIsHybridSearchOverlayOpen]),
  };
};

export default useUIStore;
