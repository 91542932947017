'use client';

import styled from 'styled-components';
import { Drawer as DrawerPrimitive } from 'vaul';
import { mediaMobile } from '../styled-utils';
import { cssVar } from '../theme/variables';

const DrawerRoot = DrawerPrimitive.Root;

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = styled(DrawerPrimitive.Overlay)`
  position: fixed;
  inset: 0;
  z-index: 125;

  background-color: ${cssVar['color-bg-overlay']};
  will-change: opacity;
`;

const DrawerContent = styled(DrawerPrimitive.Content)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 125;

  margin-top: 6rem;
  display: flex;
  flex-direction: column;

  height: auto;

  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;

  border: 1px solid ${cssVar['color-border-primary']};
  background: ${cssVar['color-bg-primary']};

  padding-bottom: env(safe-area-inset-bottom);

  &:focus-visible {
    outline: none;
  }
`;

const DrawerHandle = styled.div`
  margin: 0 auto;
  margin-top: 1rem;
  height: 0.375rem;
  width: 3rem;
  border-radius: 0.625rem;
  background: ${cssVar['color-bg-quarternary']};
`;

const DrawerHeader = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  text-align: center;

  ${mediaMobile} {
    text-align: left;
  }
`;

const DrawerTitle = styled(DrawerPrimitive.Title)`
  color: ${cssVar['color-text-primary']};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  display: inline-flex;
  align-items: center;
`;

export default Object.assign(DrawerRoot, {
  Trigger: DrawerTrigger,
  Portal: DrawerPortal,
  Close: DrawerClose,
  Overlay: DrawerOverlay,
  Content: DrawerContent,
  Handle: DrawerHandle,
  Header: DrawerHeader,
  Title: DrawerTitle,
});
