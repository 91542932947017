import {
  ResourceCreationActions,
  useMutationCreateResourceV2,
} from '@/src/modules/resources/mutations/useMutationCreateResourceV2';
import { ResourceDetailFolder, ResourceParent } from '@/src/modules/resources/resources.types';

type MutationVariables = {
  folderName?: string;
  disableToast?: boolean;
  action?: ResourceCreationActions;
  parent: ResourceParent;
};

export const useMutationCreateSubFolder = () => {
  const createResource = useMutationCreateResourceV2();

  return {
    ...createResource,
    mutate: (
      variables: MutationVariables,
      options?: Parameters<typeof createResource.mutate>[1],
    ) => {
      createResource.mutate(
        {
          data: {
            createType: 'folder',
            body: {
              name: variables.folderName ?? 'New Folder',
              parentId: variables.parent.id,
            },
            optimisticData: {
              parent: variables.parent,
            },
          },
          disableToast: variables.disableToast,
          action: variables.action,
        },
        options,
      );
    },
    mutateAsync: async (variables: MutationVariables) => {
      const res = await createResource.mutateAsync({
        data: {
          createType: 'folder',
          body: {
            name: variables.folderName ?? 'New Folder',
            parentId: variables.parent.id,
          },
          optimisticData: {
            parent: variables.parent,
          },
        },
        disableToast: variables.disableToast,
        action: variables.action,
      });

      return res as ResourceDetailFolder;
    },
  };
};
