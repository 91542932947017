import { QueryResourceListContextSearchParams, QueryResourceListParams } from '../resources.types';

export const resourceQueryKeys = {
  folders: (parentId?: string, fabricUser?: boolean) =>
    ['infinite-resource-folders', parentId, fabricUser ? 'fabricUser' : undefined] as const,
  resourceDetail: (resourceId?: string | null) => ['resource-detail', resourceId || null] as const,
  resourceBySharedSecret: (secret?: string) => ['shared-resource', secret] as const,
  resourceList: (options?: QueryResourceListParams) => ['resource-list', options || null] as const,
  resourceContextSearchList: (options?: QueryResourceListContextSearchParams) =>
    ['resource-list-context-search', options || null] as const,
  totals: (parentId?: string) => ['resource-totals-v2', parentId] as const,
};
