import { ScreenshotConnectionPlugin } from '@/mobile/ScreenshotConnection';
import { assistantStoreName } from '@/src/hooks/chatbot';
import { persisterQueriesStoreName } from '@/src/lib/react-query/persister';
import { database } from '@/src/lib/storage/global';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import useScreenshotsStore from '../store/screenshots';
import useUIStore from '../store/ui';
import { isInMobile } from './mobile';

export const resetStores = async () => {
  useUIStore.getState().reset();
  useScreenshotsStore.getState().reset();
  useAssistantStore.getState().reset();
  localStorage.clear();

  if (database) {
    database.clear(persisterQueriesStoreName);
    database.clear(assistantStoreName);
  }

  if (isInMobile()) await ScreenshotConnectionPlugin.reset();
};
