import { Query } from '@tanstack/react-query';
import { resourceQueryKeys } from './resourceQueryKeys';

export const resourceQueryPredicates = {
  resourceDetail: (query: Query, resourceId: string) => {
    const resourceDetailKey = resourceQueryKeys.resourceDetail(resourceId);

    return query.queryKey[0] === resourceDetailKey[0] && query.queryKey[1] === resourceDetailKey[1];
  },
  resourceMultiple: (query: Query, resourceIds: string[]) => {
    for (const resourceId of resourceIds) {
      if (resourceQueryPredicates.resourceDetail(query, resourceId)) return true;
    }

    return false;
  },
  resourceList: (query: Query) => {
    return Boolean(query.queryKey[0] === resourceQueryKeys.resourceList()[0]);
  },
  resourceContextSearchList: (query: Query) => {
    return Boolean(query.queryKey[0] === resourceQueryKeys.resourceContextSearchList()[0]);
  },
  /**
   * matches any resource list (v2/resources/filter) or context search list (v2/search)
   */
  resourceListAny: (query: Query) => {
    return (
      [resourceQueryKeys.resourceContextSearchList()[0], resourceQueryKeys.resourceList()[0]].find(
        (key) => key === query.queryKey[0],
      ) !== undefined
    );
  },

  resourceListByParentId: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] === resourceQueryKeys.resourceList()[0]) {
      const queryKey = query.queryKey as ReturnType<typeof resourceQueryKeys.resourceList>;
      if (queryKey[1]?.parentId === parentId && !!parentId) {
        return true;
      }
      if (!queryKey[1]?.parentId) {
        return true;
      }
    }

    return false;
  },
  resourceContextSearchListByParentId: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] === resourceQueryKeys.resourceContextSearchList()[0]) {
      const queryKey = query.queryKey as ReturnType<
        typeof resourceQueryKeys.resourceContextSearchList
      >;
      if (queryKey[1]?.filters?.parentId === parentId && !!parentId) return true;
    }
    return false;
  },

  resourceListAnyByParentId: (query: Query, parentId?: string | null) => {
    return (
      resourceQueryPredicates.resourceListByParentId(query, parentId) ||
      resourceQueryPredicates.resourceContextSearchListByParentId(query, parentId)
    );
  },

  resourceListAnyByMultipleParentIds: (query: Query, parentIds: string[]) => {
    return parentIds.some((parentId) =>
      resourceQueryPredicates.resourceListAnyByParentId(query, parentId),
    );
  },
  summaryAll: (query: Query) => {
    return query.queryKey[0] === resourceQueryKeys.totals()[0];
  },
  summaryByParent: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] !== resourceQueryKeys.totals()[0]) return false;
    if (!parentId) return true;

    return query.queryKey[1] === parentId;
  },
  summaryByParents: (query: Query, parentIds: string[]) => {
    if (query.queryKey[0] !== resourceQueryKeys.totals()[0]) return false;

    return parentIds.includes(query.queryKey[1] as string);
  },
};
