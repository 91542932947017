import { fabricHitCommentNestedToComment } from '@/src/modules/comments/utils/fabricHitCommentNestedToComment';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { rootTypeGuard } from '@/src/modules/spaces/utils/rootTypeGuard';
import {
  BaseFdoc,
  Fdoc,
  FolderFdoc,
  getThumbnail,
  ImageFdoc,
  NotepadFdoc,
  PageFdoc,
  parseResourceState,
  ResourceState,
  StoredFileFdoc,
  TextFdoc,
} from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

const getTitle = (resource: ResourceDetail) => {
  return resource.name ?? '';
};

const getExtension = (resource: ResourceDetail) => {
  return resource.extension ? '.' + resource.extension : '';
};

const getTitleAndExtension = (resource: ResourceDetail) => {
  return {
    title: getTitle(resource),
    extension: getExtension(resource),
  };
};

export const convertResourceV2ToFdoc = (
  resource: OptimisticDraft<ResourceDetail>,
): OptimisticDraft<Fdoc> => {
  const baseFdoc: OptimisticDraft<BaseFdoc> = {
    draftId: resource.draftId,
    isDraft: resource.isDraft,
    id: resource.id,
    commentCount: resource.commentCount ?? 0,
    label: resource.label?.id ? Number(resource.label.id) : null, // missing
    list: resource.root?.id ?? null,
    listData: resource.root
      ? {
          id: resource.root.folder.id,
          title: resource.root.folder.name || 'Untitled',
          type: rootTypeGuard(resource.root?.type) ? resource.root.type : 'SPACE',
          integration: resource.root?.type === 'INTEGRATION' ? resource.root.subtype : null,
          // externalId: resource.list?.externalId ?? null,
        }
      : undefined,
    createdAt: new Date(resource.createdAt).toISOString(),
    modifiedAt: new Date(resource.modifiedAt).toISOString(),
    parentResourceId: resource.parent?.id ?? null,
    isDirectShared: resource.isDirectShared,
    isShared: resource.isShared,
    stateProcessing: parseResourceState(resource.stateProcessing),
    commenters: [],
    commentPinned: resource.commentPinned
      ? fabricHitCommentNestedToComment(
          {
            ...resource.commentPinned,
            user: {
              ...resource.commentPinned.user,
              name: resource.commentPinned.user.name ?? '',
              picture: resource.commentPinned.user.pictureUrlCdn || null,
            },
          },
          resource.id,
        )
      : null,
    originUrl: resource.originUrl,
    fileUrl: resource.fileUrl,
    personal: {
      tags: resource.tags,
    },

    user: {
      ...resource.user,
      name: resource.user.name ?? '',
      picture: resource.user.pictureUrlCdn ?? null,
    },
    thumbnail: getThumbnail(resource.thumbnail),
  };

  switch (resource.kind) {
    case 'image': {
      const isWebnoteImage = resource.originUrl;

      if (isWebnoteImage) {
        const imageFdoc: ImageFdoc = {
          ...baseFdoc,
          type: 'image',
          isWebnote: true,
          data: {
            pageUrl: resource.originUrl!,
            pageTitle: resource.name ?? '',
            statePreview: resource.data.webpage
              ? parseResourceState(resource.data.webpage.status!)
              : ResourceState.PENDING,
            ...resource.data,
            noteType: 'image',
            closestPath: '',
          },
        };
        return imageFdoc;
      } else {
        const storedFileFdoc: StoredFileFdoc = {
          ...baseFdoc,
          type: 'stored_file',
          isWebnote: false,
          data: {
            ...getTitleAndExtension(resource),
            thumbnail: getThumbnail(resource.thumbnail),
            contentType: resource.mimeType,
            contentLength: resource.size,
            url: resource.fileUrl!,
          },
        };
        return storedFileFdoc;
      }
    }
    case 'audio': {
      const audioFdoc: StoredFileFdoc = {
        ...baseFdoc,
        type: 'stored_file',
        isWebnote: false,
        data: {
          ...getTitleAndExtension(resource),
          thumbnail: getThumbnail(resource.thumbnail),
          contentType: resource.mimeType,
          contentLength: resource.size,
          url: resource.fileUrl!,
        },
      };
      return audioFdoc;
    }
    case 'video': {
      const audioFdoc: StoredFileFdoc = {
        ...baseFdoc,
        type: 'stored_file',
        isWebnote: false,
        data: {
          ...getTitleAndExtension(resource),
          thumbnail: getThumbnail(resource.thumbnail),
          contentType: resource.mimeType,
          contentLength: resource.size,
          url: resource.fileUrl!,
        },
      };
      return audioFdoc;
    }
    case 'document': {
      const audioFdoc: StoredFileFdoc = {
        ...baseFdoc,
        type: 'stored_file',
        isWebnote: false,
        data: {
          ...getTitleAndExtension(resource),
          thumbnail: getThumbnail(resource.thumbnail),
          contentType: resource.mimeType,
          contentLength: resource.size,
          url: resource.fileUrl!,
        },
      };
      return audioFdoc;
    }
    case 'notepad': {
      const audioFdoc: NotepadFdoc = {
        ...baseFdoc,
        type: 'notepad',
        isWebnote: false,
        data: {
          title: resource.name ?? '',
          editorjs: resource.data.preview,
          createdAt: new Date(resource.createdAt),
          modifiedAt: new Date(resource.modifiedAt),
          /**
           * @TODO missing in api
           */
          isYjsEnabled: true,
        },
      };
      return audioFdoc;
    }
    case 'bookmark': {
      const bookmarkData: PageFdoc = {
        ...baseFdoc,
        isWebnote: true,
        type: 'page',
        data: {
          pageUrl: resource.originUrl!,
          pageTitle: resource.name ?? '',
          statePreview: resource.data?.webpage
            ? parseResourceState(resource.data.webpage.status!)
            : ResourceState.PENDING,
          ...resource.data,
          noteType: 'page',
          screenshotUrl: resource.data?.webpage?.screenshot?.url,
          textUrl: resource.data?.webpage?.reader?.url,
        },
      };

      return bookmarkData;
    }
    case 'highlight': {
      if (!resource.data.extensionContext) {
        console.error('Highlight resource does not have extensionContext');
      }

      const highlightData: TextFdoc = {
        ...baseFdoc,
        isWebnote: true,
        type: 'text',
        data: {
          pageUrl: resource.originUrl!,
          pageTitle: resource.name ?? '',
          statePreview: resource.data.webpage
            ? parseResourceState(resource.data.webpage.status!)
            : ResourceState.PENDING,
          ...resource.data,
          noteType: 'text',
          ...resource.data.extensionContext,
          text: resource.data.extensionContext?.text ?? '',
          textRange: resource.data.extensionContext?.textRange || {
            end: {
              closestPath: '',
              sentence: '',
            },
            endOffset: 0,
            start: {
              closestPath: '',
              sentence: '',
            },
            startOffset: 0,
          },
        },
      };
      return highlightData;
    }

    case 'default': {
      const defaultFdoc: StoredFileFdoc = {
        ...baseFdoc,
        type: 'stored_file',
        isWebnote: false,
        data: {
          ...getTitleAndExtension(resource),
          thumbnail: getThumbnail(resource.thumbnail),
          contentType: resource.mimeType,
          contentLength: resource.size,
          url: resource.fileUrl!,
        },
      };
      return defaultFdoc;
    }
    case 'folder': {
      const folderFdoc: FolderFdoc = {
        ...baseFdoc,
        type: 'folder',
        isWebnote: false,
        data: {
          name: resource.name ?? '',
        },
      };
      return folderFdoc;
    }
  }
};
