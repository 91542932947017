import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { emptyResources, emptyTotal } from '@/src/modules/resources/queries/useQueryResourceList';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import { WoodyError } from '@fabric/woody-client';
import {
  InfiniteData,
  InfiniteQueryObserverOptions,
  keepPreviousData,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import {
  QueryResourceList_PageBase,
  QueryResourceListContextSearchParams,
  ResourceDetail,
} from '../resources.types';
import { resourceQueryKeys } from './resourceQueryKeys';

export interface QueryResourceContextSearchPage extends QueryResourceList_PageBase {
  _meta: {
    options: QueryResourceListContextSearchParams;
    pagination: {
      page: number;
      pageSize: number;
    };
  };
}

const filterSelector = (data: InfiniteData<QueryResourceContextSearchPage>) => {
  const allResources = data.pages.flatMap((page) => page.resources);
  return {
    total: data.pages[0].total,
    pages: data.pages.map((page) => ({
      pageTotal: page.resources.length,
    })),
    resources: allResources,
  };
};

export type QueryResourceListDataState = InfiniteData<
  QueryResourceContextSearchPage,
  string | undefined
>;

export type QueryResourceListQueryOptions = Partial<
  InfiniteQueryObserverOptions<
    QueryResourceContextSearchPage,
    WoodyError,
    QueryResourceListDataState,
    QueryResourceContextSearchPage,
    QueryKey,
    number | undefined
  >
>;

/**
 * fetches resources based on the provided options
 *
 * @param queryOptions
 * @returns
 */
export const useQueryResourceContextSearch = (
  params: QueryResourceListContextSearchParams,
  queryOptions?: QueryResourceListQueryOptions,
) => {
  const { client } = useWoody();

  const query = useInfiniteQuery({
    queryKey: resourceQueryKeys.resourceContextSearchList(params),
    queryFn: async ({ pageParam }) => {
      const pagination = {
        page: pageParam ?? 1,
        pageSize: 20,
      };

      const pageData = await client.v2('/v2/search', {
        method: 'post',
        body: {
          ...params,
          pagination,
        },
      });

      return {
        // This allows the select function to have access to the options used in the query
        // And we avoid having to memoize the select function
        nextCursor: null,
        total: pageData.total,
        hasMore: pageData.hasMore,
        resources: pageData.hits as (ResourceDetail & { score: number })[],
        _meta: {
          options: params!,
          pagination,
        },
      };
    },
    placeholderData: keepPreviousData,
    staleTime: 15000,
    refetchInterval: 45000,
    retry: (failureCount, error) => {
      if (isWoodyError(error) && error.status === 404) {
        return failureCount < 1;
      }

      return failureCount < 4;
    },
    ...queryOptions,
    select: filterSelector,
    enabled: isQueryEnabled([queryOptions?.enabled, !!params]),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.hasMore ? lastPage._meta.pagination.page + 1 : undefined;
    },
  });
  // queryDataRef.current = query.data;

  return {
    ...query,
    resources: query.data?.resources || emptyResources,
    total: query.data?.total ?? emptyTotal,
  };
};
