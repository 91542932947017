import { CODE_KEY_MAP } from '@/src/modules/keyboardShortcuts/codeKeyMap';
import { modifierKeys } from '@/src/modules/keyboardShortcuts/modifierKeys';
import { RecordedHotkey } from '@/src/modules/keyboardShortcuts/types';

export const recordedHotkeysToAccelerator = (recordedHotkeys: RecordedHotkey[]) => {
  return recordedHotkeys
    .map((hotkey) => {
      if (modifierKeys.includes(hotkey.value.toLowerCase())) {
        return hotkey.value;
      }

      /**
       * e.g. KeyN => N
       */
      const sanitized = hotkey.code.replace(/Key|Digit/g, '');

      return CODE_KEY_MAP[sanitized.toLowerCase()] || sanitized;
    })
    .join('+');
};
