import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import { useEffect, useState } from 'react';

/**
 * Will run the given function once given a condition per mount.
 * @param func - The function to run.
 * @param condition - The condition to run the function.
 */
export function useRunOnce<F extends () => void | Promise<unknown>>(func: F, condition = true) {
  const funcRef = useReferencedFn(func);
  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    if (!condition || hasRun) return;

    funcRef.current?.();
    setHasRun(true);
  }, [condition, hasRun, funcRef]);

  return hasRun;
}
