export const CSS_THEME = {
  /**
   * Primary text color
   * Usage: Headlines
   */
  ['color-text-primary']: {
    definitionType: 'color',
    light: '#000000',
    dark: '#eeeeee', // to be defined
  },
  ['color-text-primary-reverse']: {
    definitionType: 'color',
    light: '#eeeeee',
    dark: '#111111', // to be defined
  },
  /**
   * Secondary text color
   * Usage: paragraphs
   */
  ['color-text-secondary']: {
    definitionType: 'color',
    light: '#475569',
    dark: '#dddddd', // to be defined
  },
  /**
   * Tertiary text color
   * Usage: accompanying text, captions, ...
   */
  ['color-text-tertiary']: {
    definitionType: 'color',
    light: '#666666',
    dark: '#bbbbbb', // to be defined
  },
  /**
   * quar text color
   * Usage: accompanying text, captions, ...
   */
  ['color-text-quaternary']: {
    definitionType: 'color',
    light: '#7C838A',
    dark: '#aaaaaa', // to be defined
  },
  /**
   * delete text color
   * Usage: items to be deleted, ...
   */
  ['color-app-delete']: {
    definitionType: 'color',
    light: '#FF0000',
    dark: '#ff0000', // to be defined
  },

  ['color-app-success']: {
    definitionType: 'color',
    light: '#6edc5f',
    dark: '#6edc5f',
  },

  ['color-text-placeholder']: {
    definitionType: 'color',
    light: '#B2B2B2',
    dark: '#666666', // to be defined
  },

  /**
   * Main Fabric blue color
   */
  ['color-app-primary']: {
    definitionType: 'color',
    light: '#0011ff',
  },
  /**
   * blue becomes unreadable on dark background
   * use this color specificaly for text or where the primary color should have better contrast
   */
  ['color-app-primary-text']: {
    definitionType: 'color',
    light: '#0011ff',
    dark: '#D1D4FF',
  },
  /**
   * on dark theme the primary color changes to white
   */
  ['color-app-primary-text-whiteOnDark']: {
    definitionType: 'color',
    light: '#0011ff',
    dark: '#FFFFFF',
  },

  /**
   * Secondary Fabric purpelish color
   */
  ['color-app-secondary']: {
    definitionType: 'color',
    light: '#4200ff',
    dark: '#EAE3FF',
  },

  /**
   * Radius used on inputs
   * Usage: Buttons, dropdown buttons, text inputs, ...
   */
  ['radius-input-sm']: {
    definitionType: 'size',
    value: '0.25rem',
  },
  ['radius-input']: {
    definitionType: 'size',
    value: '0.5rem',
  },
  ['radius-input-lg']: {
    definitionType: 'size',
    value: '0.625rem',
  },

  /**
   * Background colors
   */
  ['color-bg-contrast']: {
    definitionType: 'color',
    light: '#FFFFFF',
    dark: '#000000',
  },
  ['color-bg-contrast-reverse']: {
    definitionType: 'color',
    light: '#000000',
    dark: '#FFFFFF',
  },

  ['color-bg-primary']: {
    definitionType: 'color',
    light: '#FFFFFF',
    dark: '#1f1f1f', // to be defined
  },
  ['color-bg-secondary']: {
    definitionType: 'color',
    light: '#fcfcfd',
    dark: '#262626', // to be defined
  },
  ['color-bg-section']: {
    definitionType: 'color',
    light: '#f4f4f4',
    dark: '#2e2e2e',
  },
  ['color-bg-section-primary']: {
    definitionType: 'color',
    light: '#F5F1FE',
    dark: '#4A4A5E',
  },
  ['color-bg-secondary-button']: {
    definitionType: 'color',
    light: '#fafafa',
    dark: '#282828', // to be defined
  },
  ['color-bg-tertiary']: {
    definitionType: 'color',
    light: '#eff1f3',
    dark: '#2b2b2b', // to be defined
  },
  ['color-bg-quarternary']: {
    definitionType: 'color',
    light: '#e6e6e6',
    dark: '#313131', // to be defined
  },
  ['color-bg-quinary']: {
    definitionType: 'color',
    light: '#d9d9d9',
    dark: '#343434', // to be defined
  },
  ['color-bg-senary']: {
    definitionType: 'color',
    light: '#c5c5c5',
    dark: '#3c3c3c',
  },
  ['color-bg-disabled']: {
    definitionType: 'color',
    light: '#c5c5c5',
    dark: '#3c3c3c', // to be defined
  },
  ['color-bg-septenary']: {
    definitionType: 'color',
    light: '#b2b2b2',
    dark: '#434343', // to be defined
  },
  ['color-bg-octonary']: {
    definitionType: 'color',
    light: '#929292',
    dark: '#5c5c5c', // to be defined
  },
  ['color-bg-overlay']: {
    definitionType: 'color',
    light: 'rgba(193, 193, 193, 0.70)',
    dark: 'rgba(72, 72, 72, 0.70)',
  },
  ['color-bg-nonary']: {
    definitionType: 'color',
    light: '#2f2f2f',
    dark: '#f6f6f6', // to be defined
  },

  ['color-bg-primary-reverse']: {
    definitionType: 'color',
    light: '#000000',
    dark: '#eeeeee', // to be defined
  },

  ['color-border-primary']: {
    definitionType: 'color',
    light: '#e3e3e4',
    dark: '#444444', // to be defined
  },
  ['color-border-secondary']: {
    definitionType: 'color',
    light: '#ECECEC',
    dark: '#3a3a3a', // to be defined
  },

  ['color-border-tertiary']: {
    definitionType: 'color',
    light: '#F3F3F3',
    dark: '#3a3a3a', // to be defined
  },
  ['color-bg-note-primary']: {
    definitionType: 'color',
    light: '#fffbec',
    dark: '#333333',
  },
  ['color-text-note-primary']: {
    definitionType: 'color',
    light: '#7C838A',
    dark: '#DEDEDE',
  },

  /**
   * Box shadow spreads
   */
  ['shadow-resource-card']: {
    definitionType: 'shadow',
    value: '0 0 2px 0 rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .02);',
  },
  ['shadow-spread-default']: {
    definitionType: 'size',
    value: '0px 1px 34px 0px',
  },
  ['shadow-spread-xs']: {
    definitionType: 'size',
    value: '0px 4px 6px 0px',
  },
  ['shadow-spread-large']: {
    definitionType: 'size',
    value: '0px 1px 84px 0px',
  },
  ['shadow-spread-outline']: {
    definitionType: 'size',
    value: '0px 0px 0px 1px',
  },
  ['shadow-spread-none']: {
    definitionType: 'size',
    value: '0px 0px 0px 0px',
  },
  ['shadow-spread-within']: {
    definitionType: 'size',
    value: '0 0 10rem 0',
  },

  // base input height for buttons, text inputs
  ['height-input']: {
    definitionType: 'size',
    value: '2.5rem',
  },
  ['height-input-lg']: {
    definitionType: 'size',
    value: '3rem',
  },
  ['resource-hover-overlay-color']: {
    definitionType: 'rgba',
    value: 'rgba(89, 111, 134, 0.07)',
  },
  ['height-input-xl']: {
    definitionType: 'size',
    value: '3.25rem',
  },
  ['height-input-sm']: {
    definitionType: 'size',
    value: '2.125rem',
  },
  ['color-zoom-slider-track']: {
    definitionType: 'color',
    light: '#e6e6e6',
    dark: '#444444',
  },
  ['bg-expanded-resource-extra-panel']: {
    definitionType: 'color',
    light: '#444444',
    dark: '#1f1f1f',
  },
  ['bg-expanded-resource-extra-panel-reverse']: {
    definitionType: 'color',
    light: '#1f1f1f',
    dark: '#444444',
  },
  ['color-sidebar-resize-thumb']: {
    definitionType: 'color',
    light: '#BFBFBF',
    dark: '#999999',
  },

  ['preview-compact-card-aspect-ratio']: {
    definitionType: 'string',
    value: '168 / 192',
  },
} as const;

export type CSS_THEME = typeof CSS_THEME;
export type CSS_THEME_COLOR = {
  [K in keyof CSS_THEME]: CSS_THEME[K]['definitionType'] extends 'color' ? K : never;
}[keyof CSS_THEME];
