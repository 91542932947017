import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { getBrowserClientTimeZone } from '@/src/lib/browser/getBrowserClientTimeZone';
import { userQueryKeys } from '@/src/modules/user/queries/userQueryKeys';
import { UserPreferences } from '@/src/modules/user/user.types';
import { useWoody } from '@/src/services/woody/woody';
import { components } from '@fabric/woody-client';
import { keepPreviousData, QueryObserverOptions, useQuery } from '@tanstack/react-query';

export const useQueryUserPreferences = (
  queryOptions?: Partial<QueryObserverOptions<UserPreferences>>,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  return useQuery({
    queryKey: userQueryKeys.userPreferences(),
    queryFn: async () => {
      const data = (await client.v2('/v2/user/preferences')) as unknown as UserPreferences;

      /**
       * we try to set the timezone automatically for the user if it's not set
       */
      if (!data || !data.timezone) {
        try {
          const currentBrowserTimezone = getBrowserClientTimeZone();

          await client.v2('/v2/user/preferences', {
            method: 'put',
            body: {
              timezone: currentBrowserTimezone ?? null,
            } as components['schemas']['UserPreferences'],
          });

          // we might want to refetch rather merge
          return {
            ...data,
            timezone: currentBrowserTimezone,
          } as UserPreferences;
        } catch {
          // it's fine if we can't read the timezone
        }
      }

      return (data ?? {
        timezone: undefined,
      }) as UserPreferences;
    },
    placeholderData: keepPreviousData,
    enabled: isLoggedIn,
    ...queryOptions,
  });
};
