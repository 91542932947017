import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { getNextPageParam } from '@/src/lib/react-query/utilities';
import { getIntegrationViewConfig } from '@/src/modules/connections/connections.config';
import {
  ApiIntegration,
  ApiIntegrationWithViewConfig,
} from '@/src/modules/connections/connections.types';
import { rootQueryKeys } from '@/src/modules/resource-roots/queries/rootQueryKeys';
import { UseQueryResourceRootFnData } from '@/src/modules/resource-roots/queries/roots.queries.types';
import {
  ApiResourceRoot,
  ResourceRoot,
  ResourceRootIntegration,
  ResourceRootListFilter,
  ResourceRootSpace,
  ResourceRootSystem,
} from '@/src/modules/resource-roots/resource-roots.types';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { convertResourceV2ToFdoc } from '@/src/modules/resources/utils/convertResourceV2ToFdoc';

import { useWoody } from '@/src/services/woody/woody';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

const defaultRoots: ResourceRoot[] = [];
const defaultIntegrations: ApiIntegrationWithViewConfig[] = [];

export interface UseQueryResourceRootListQueryConfig {
  enabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
}

const initialPageParam = {
  /**
   * @TODO
   * unreasonable, we don't handle continuous fetching in UI just yet
   * we probably need to load all at once anyway, but batch loading could be a good idea
   */
  limit: 500,
  offset: 0,
  count: 0,
};

export const useQueryResourceRootList = (
  queryFilter?: ResourceRootListFilter,
  queryConfig?: UseQueryResourceRootListQueryConfig,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();
  const query = useInfiniteQuery({
    queryKey: rootQueryKeys.resourceRootList(queryFilter),
    queryFn: async ({ pageParam: { limit, offset } }) => {
      const response = await client.v2('/v2/resource-roots', {
        query: {
          ...queryFilter,
          limit,
          offset,
          includeMembers: 10,
          includeResources: 10,
        },
      });

      const { integrations, sharedWith, resources } = response.data;
      const roots = response.data.roots as ApiResourceRoot[];
      const mappedRoots: ResourceRoot[] = roots.map((root) => {
        const rootSharedWith = sharedWith?.[root.id];
        const rootResources = resources?.[root.id]
          ? (resources[root.id] as ResourceDetail[])
          : undefined;

        const nextRoot = {
          ...root,
          integration: null,
          folder: {
            ...root.folder,
            sharedWith: rootSharedWith,
            resources: rootResources,
            fdocs: rootResources?.map(convertResourceV2ToFdoc),
          },
        };

        if (root.type === 'INTEGRATION') {
          const integration = integrations.find((integration) =>
            integration.mirrorRegistries.find((registry) => registry.rootId === root.id),
          );

          return {
            ...nextRoot,
            integration,
          } as ResourceRootIntegration;
        }
        return nextRoot as ResourceRootSpace | ResourceRootSystem;
      });

      const result: UseQueryResourceRootFnData = {
        count: response.count,
        roots: mappedRoots,
        integrations: (response.data.integrations || []) as ApiIntegration[],
      };
      return result;
    },
    // initialData,
    initialPageParam,
    getNextPageParam: getNextPageParam,
    select: (data) => {
      if (!data) {
        return data;
      }

      const roots = data.pages
        .map((page) =>
          page.roots.map((root) => {
            /**
             * we map and attach the viewConfig here because we don't want it to be stored in the DB
             * - issues with storing react objects
             */
            if (root.integration) {
              return {
                ...root,
                integration: {
                  ...root.integration,
                  viewConfig: getIntegrationViewConfig(root.integration),
                },
              };
            }
            return root;
          }),
        )
        .flat();

      const integrations = data.pages
        .map((page) => page.integrations)
        .flat()
        .map((integration) => ({
          ...integration,
          viewConfig: getIntegrationViewConfig(integration),
        }));
      return {
        ...data,
        count: data.pages[0]?.count,
        integrations,
        roots,
      };
    },
    placeholderData: keepPreviousData,
    /**
     * prevent immediate subsequent mounts of this query to fetch data
     */
    staleTime: 1000 * 10,
    refetchInterval: 1000 * 60,
    ...queryConfig,
    enabled: isQueryEnabled([isLoggedIn, queryConfig?.enabled ?? true]),
  });

  return {
    ...query,
    roots: query.data?.roots || defaultRoots,
    integrations: query.data?.integrations || defaultIntegrations,
  };
};
