import {
  useQueryResourceRootList,
  UseQueryResourceRootListQueryConfig,
} from '@/src/modules/resource-roots/queries/useQueryResourceRootList';
import {
  ResourceRootListFilter,
  RootSpaceOrSystem,
} from '@/src/modules/resource-roots/resource-roots.types';
import { useMemo } from 'react';

export const useQueryResourceRootSpaceSystemList = (
  queryFilter?: Omit<ResourceRootListFilter, 'types'>,
  options?: { includeSystem?: boolean; includeInbox?: boolean },
  queryConfig?: UseQueryResourceRootListQueryConfig,
) => {
  const rootQuery = useQueryResourceRootList(queryFilter, queryConfig);

  const spaceRoots = useMemo(() => {
    const filteredRoots = rootQuery.roots.filter((resourceRoot) => {
      if (resourceRoot.type === 'SPACE') {
        return true;
      }

      if (
        options?.includeInbox &&
        resourceRoot.type === 'SYSTEM' &&
        resourceRoot.subtype === 'inbox'
      ) {
        return true;
      }

      if (options?.includeSystem && resourceRoot.type === 'SYSTEM') {
        return true;
      }

      return false;
    });

    return filteredRoots.sort((root1, root2) => {
      if (root1.type === root2.type) {
        return (root1.folder.name || '').localeCompare(root2.folder.name || '');
      }

      return root1.type === 'SYSTEM' ? -1 : 1;
    }) as RootSpaceOrSystem[];
  }, [rootQuery.roots, options]);

  return {
    ...rootQuery,
    /**
     * ordered alphabetically by name
     * type 'system' is always on top
     */
    spaceRoots,
  };
};
