import { RecordedHotkey } from '@/src/modules/keyboardShortcuts/types';
import { PermissionRole } from '@/src/modules/permission/permission.types';
import { components } from '@fabric/woody-client';

export enum UserGroup {
  ADMIN = 'admin',
  GOOGLE_DRIVE_BETA = 'google-drive-beta',
  FABRIC_INTERNAL = 'fabric-internal',
}

export type ApiUserMe = components['schemas']['MineProfile'];
export type ApiUserPublic = components['schemas']['UserProfilePublic'];

export interface ApiUserPublicWithRole extends ApiUserPublic {
  role: PermissionRole;
}

export interface ApiUserPublicWithRoleAndOnlineStatus extends ApiUserPublic {
  role: PermissionRole;
  online?: boolean;
}

export type SubscritionTier = ApiUserMe['subscription']['tier'];

export type SavedShortcut = {
  recordedKeys?: RecordedHotkey[] | null | undefined;
  disabled?: boolean;
};
export interface UserKeyboardShortcutsDesktop {
  quickNote?: SavedShortcut;
  quickSearch?: SavedShortcut;
}

export type KeyboardShortcutDesktopScope = 'macOsDesktopAppShortcuts' | 'desktopAppShortcuts';

interface KeyboardShortcutDesktopAppScopesConfig
  extends Record<KeyboardShortcutDesktopScope, UserKeyboardShortcutsDesktop> {}

/**
 * extension related shortcut config
 */

//TBD, maybe we split this per browser... dunno
export type KeyboardShortcutExtensionScope = 'anyExtensionShortcuts';

export interface UserKeyboardShortcutsExtension {
  savePageAsBookmark?: SavedShortcut;
}

interface KeyboardShortcutExtensionScopesConfig
  extends Record<KeyboardShortcutExtensionScope, UserKeyboardShortcutsExtension> {}

/**
 * User preferences
 */
export type UserPreferences = {
  timezone: string | null;
  frontend: {
    onboardingChecklistShow?: boolean;
    onboardingChecklist?: {
      [key: string]: boolean;
    };

    migrateBannerHidden?: boolean;
  } & Partial<KeyboardShortcutDesktopAppScopesConfig> &
    Partial<KeyboardShortcutExtensionScopesConfig>;
};
