import { QueryKey } from '@tanstack/react-query';
import { resourceQueryKeys } from '../queries/resourceQueryKeys';

export const isResourceDetailQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.resourceDetail> => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    resourceQueryKeys.resourceDetail()[0] === queryKey[0] &&
    queryKey[1] !== undefined &&
    typeof queryKey[1] === 'string'
  );
};

export const isResourceListQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.resourceList> => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    resourceQueryKeys.resourceList()[0] === queryKey[0]
  );
};

export const isResourceListContextSearchQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.resourceContextSearchList> => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    resourceQueryKeys.resourceContextSearchList()[0] === queryKey[0]
  );
};
