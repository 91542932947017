import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import styled, { css, CSSProperties } from 'styled-components';
import { cssVar } from '../theme/variables';
import { Flex } from './Flex';

const color = {
  primary: cssVar['color-text-primary'],
  secondary: cssVar['color-text-secondary'],
  tertiary: cssVar['color-text-tertiary'],
  quaternary: cssVar['color-text-quaternary'],
  placeholder: cssVar['color-text-placeholder'],
  inherit: 'inherit',
  ['app-secondary']: `rgba(${cssVar['color-app-secondary-rgb']}, 0.6)`,
  ['app-primary']: cssVar['color-app-primary-text'],
  delete: cssVar['color-app-delete'],
};

interface withColorProps {
  color?: keyof typeof color;
}

type fontSize = 'xxs' | 'xs' | 'sm' | 'sm-plus' | 'md' | 'lg' | 'xl' | 'inherit';

export interface PProps extends withColorProps {
  weight?: number;
  size?: fontSize;
  ellipsis?: boolean;
  wordBreak?: CSSProperties['wordBreak'];
  tt?: CSSProperties['textTransform'];
}

const cssFontSizeProperties = css<PProps>`
  ${(p) => {
    switch (p.size) {
      case 'xxs':
        return css`
          font-size: 0.75rem; // 12px
          line-height: 0.875rem;
        `;
      case 'xs':
        return css`
          font-size: 0.8125rem; // 13px
          line-height: 1.125rem;
        `;
      case 'sm':
        return css`
          font-size: 0.875rem; // 14px
          line-height: 1.125rem;
        `;
      case 'sm-plus':
        return css`
          font-size: 0.9rem; // 14.4px
          line-height: 1.1875rem;
        `;
      case 'md':
        return css`
          font-size: 0.9375rem; // 15px
          line-height: 1.25rem;
        `;
      case 'lg':
        return css`
          font-size: 1rem; // 16px
          line-height: 1.5rem;
        `;
      case 'xl': // 18px
        return css`
          font-size: 1.125rem; // 18px
          line-height: 1.5rem;
        `;
    }
  }}
`;

/**
 * paragrahp text
 */
export const P = styled.p<PProps>`
  ${(p) => `color: ${color[p.color || 'primary']};`}
  ${cssFontSizeProperties}
  font-weight: ${({ weight }) => weight || 400};
  ${(p) => p.ellipsis && cssTextEllipsis}
  ${(p) =>
    p.tt &&
    css`
      text-transform: ${p.tt};
    `}
  ${(p) =>
    p.wordBreak &&
    css`
      word-break: ${p.wordBreak};
    `}
`;

export const SPAN = styled(P).attrs((props) => {
  return {
    as: 'span',
    ...props,
  };
})``;

export const TypographyContainer = styled(Flex).attrs((props) => {
  return {
    direction: 'column',
    gap: 'text',
    // spread the given props or the we are unable to override the default props
    ...props,
  };
})``;

export const SectionTitle = styled(P).attrs({
  color: 'secondary',
})`
  display: block;
  font-weight: 500;
`;

export const H1 = styled.div<withColorProps>`
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2.25rem;
  color: ${(p) => color[p.color || 'primary']};
`;

export const H2 = styled.div<withColorProps>`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${(p) => color[p.color || 'primary']};
`;

export const UL = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  color: ${cssVar['color-text-secondary']};
`;

export const LI = styled.li`
  font-size: 15px;
  font-weight: 400;
  gap: 4px;
`;

export const A = styled.a<withColorProps>`
  ${(p) => `color: ${color[p.color || 'primary']};`}
  text-decoration: underline;
  cursor: pointer;
`;

export const DL = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  padding: 0;
`;

export const DLItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const DT = styled.dt<PProps>`
  ${(p) => `color: ${color[p.color || 'quaternary']};`}
  ${cssFontSizeProperties}
  font-weight: ${({ weight }) => weight || 600};
  text-align: left;

  &::after {
    content: ':';
  }
`;

export const DD = styled.dd<PProps>`
  ${(p) => `color: ${color[p.color || 'primary']};`}
  ${cssFontSizeProperties}
  font-weight: ${({ weight }) => weight || 400};
  margin: 0;

  white-space: break-spaces;
  word-break: break-word;
`;
