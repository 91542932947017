import { addPendingMutation } from '@/src/lib/react-query/pending';
import {
  upsertItemInArrayById,
  useOptimisticallyUpdateInfiniteQueriesWithPredicate,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { mutateResourceMeta } from '@/src/modules/resource-detail/utils/mutateResourceMeta';
import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import {
  InfiniteDataResourceList,
  InfiniteDataResourceListPageBase,
} from '@/src/modules/resources/queries/types';
import { QueryResourceListPage } from '@/src/modules/resources/queries/useQueryResourceList';
import { FilteredFdocs, ResourceDetail } from '@/src/modules/resources/resources.types';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { isTruthy } from '@/src/utils/guards';
import { useQueryClient } from '@tanstack/react-query';
import { resourceMatchesQueryFilters } from './resourceMatchesQueryFilters';

type ResourceDetailWithOptionalProperties = Partial<Omit<ResourceDetail, 'resources'>> & {
  id: string;
};
type ReplaceOptions = {
  createIfNotFound?: boolean;
};

export const useQueryCacheResourceHelpers = () => {
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const optimisticallyUpdateInfiniteQueries = useOptimisticallyUpdateInfiniteQueriesWithPredicate();

  return {
    updateCachedResource: (
      nextResource: ResourceDetailWithOptionalProperties,
      options?: {
        resourceUpdateFn?: (resource: ResourceDetail) => ResourceDetail;
      },
    ) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.resourceListAny(q) ||
          resourceQueryPredicates.resourceDetail(q, nextResource.id),
        type: 'active',
      });

      /**
       * updating list of resources where the resource COULD be present, both filter queries (direct DB) or search queries (elastic search)
       */
      const optimisticallyUpdateResourceListQueries =
        optimisticallyUpdateInfiniteQueries<QueryResourceListPage>({
          predicate: resourceQueryPredicates.resourceListAny,
          pageUpdater: (page) => ({
            ...page,
            resources: upsertItemInArrayById(page.resources, nextResource, {
              createIfNotFound: false,
              updateFn: options?.resourceUpdateFn,
            }),
          }),
        });

      const optimisticallyUpdatedResourceDetailQueries =
        optimisticallyUpdateQueries<ResourceDetail>(
          (q) => resourceQueryPredicates.resourceDetail(q, nextResource.id),
          (resource) =>
            resource && {
              ...resource,
              ...(nextResource as any),
            },
        );

      return {
        optimisticallyUpdateResourceListQueries,
        optimisticallyUpdatedResourceDetailQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateResourceListQueries.resetQueriesData();
          optimisticallyUpdatedResourceDetailQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateResourceListQueries.invalidateQueries();
          optimisticallyUpdatedResourceDetailQueries.invalidateQueries();
        },
      };
    },
    deleteCachedResources: (resourceIds: string[]) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.resourceListAny(q) ||
          resourceQueryPredicates.resourceMultiple(q, resourceIds),
        type: 'active',
      });

      const deletedResources: ResourceDetail[] = [];
      const deletedResourceIds: Set<string> = new Set(resourceIds);

      /**
       * Need some custom logic here, because we need to delete children and subchildren, so we need to gather all children
       */
      const queries = queryClient.getQueriesData<InfiniteDataResourceList>({
        predicate: resourceQueryPredicates.resourceListAny,
        type: 'active',
      });

      const tree = new Map<string, ResourceDetail[]>();
      for (const [_, data] of queries) {
        if (!data) {
          continue;
        }

        for (const resource of data.pages.flatMap((page) => page.resources)) {
          if (!resource.parent?.id) {
            continue;
          }

          if (!tree.has(resource.parent.id)) {
            tree.set(resource.parent.id, []);
          }

          tree.get(resource.parent.id)!.push(resource);
        }
      }

      // now that we have the tree, we can create a delete list with DFS
      // we want the list of resource IDs that are children of the ids in deletedResourceIds and 1 them to the deletedResourceIds set
      const stack = [...resourceIds];

      while (stack.length > 0) {
        const resourceId = stack.pop()!;
        if (!tree.has(resourceId)) {
          continue;
        }

        for (const resource of tree.get(resourceId)!) {
          if (!deletedResourceIds.has(resource.id)) stack.push(resource.id);

          deletedResourceIds.add(resource.id);
        }
      }

      /**
       * updating list of resources where the resource COULD be present, both filter queries (direct DB) or search queries (elastic search)
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<InfiniteDataResourceListPageBase>({
          predicate: resourceQueryPredicates.resourceListAny,
          pageUpdater: (page) => {
            const updatedResults = page.resources.filter((r) => {
              if (deletedResourceIds.has(r.id)) {
                deletedResources.push(r);
                return false;
              }

              return true;
            });

            const difference = page.resources.length - updatedResults.length;
            return {
              ...page,
              resources: updatedResults,
              // we make sure to change the total because this affects how many skeletons are shown
              total: Math.max(0, (page.total ?? 0) - difference),
            };
          },
        });

      /**
       * updating the resource directly, in this case we don't need to update the resource itself, just mark it as deleted on it's meta
       */
      const optimisticallyUpdateDirectQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceMultiple(q, Array.from(deletedResourceIds)),
        (resource) => resource && mutateResourceMeta(resource, { isDeleting: true }),
      );

      return {
        deletedResources: [...deletedResources].filter(
          (resource, index, self) => self.findIndex((r) => r.id === resource.id) === index,
        ),
        optimisticallyUpdateFilterAndSearchAllQueries,
        optimisticallyUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
          optimisticallyUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
          optimisticallyUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    addNewResourcesToCache: (newResources: OptimisticDraft<ResourceDetail>[]) => {
      // this is a bit more complex because we are adding more than one resource and they can be in different parents
      // to minimize the amount of query related functions first we map the resources to their parentResourceId
      const resourcesByParent = newResources
        .filter((resource) => !!resource.parent?.id)
        .reduce(
          (acc, resource) => {
            if (!acc[resource.parent!.id!]) {
              acc[resource.parent!.id!] = [];
            }

            acc[resource.parent!.id!].push(resource);

            return acc;
          },
          {} as Record<string, ResourceDetail[]>,
        );

      // we cancel all queries that could be affected by the new resources
      Object.keys(resourcesByParent).forEach((parentResourceId) => {
        queryClient.cancelQueries({
          predicate: (q) => resourceQueryPredicates.resourceListAnyByParentId(q, parentResourceId),
          type: 'active',
        });
      });

      // we create the optimistic updates for each parent
      const optimisticUpdates = Object.entries(resourcesByParent).map(
        ([parentResourceId, resources]) => {
          const optimisticallyUpdateFilterAndSearchQueries =
            optimisticallyUpdateInfiniteQueries<InfiniteDataResourceListPageBase>({
              predicate: (q) =>
                resourceQueryPredicates.resourceListAnyByParentId(q, parentResourceId),
              pageUpdater: (page, index, key) => {
                if (index > 0) return page;

                const validResources = resources.filter((r) => resourceMatchesQueryFilters(r, key));
                const nextResources = [...validResources, ...page.resources];

                const diff = nextResources.length - page.resources.length;

                return {
                  ...page,
                  resources: nextResources,
                  total: page.total + diff,
                };
              },
            });

          return {
            parentResourceId,
            optimisticallyUpdateFilterAndSearchQueries,
          };
        },
      );

      // we create the optimistic updates for each resource
      const optimisticUpdateDirectQueries = newResources.map((resource) =>
        optimisticallyUpdateQueries<ResourceDetail>(
          (q) => resourceQueryPredicates.resourceDetail(q, resource.id),
          () => resource,
        ),
      );

      const allQueryKeys = queryClient
        .getQueryCache()
        .findAll({
          predicate: (query) =>
            resourceQueryPredicates.resourceMultiple(
              query,
              newResources.map((r) => r.id),
            ) ||
            resourceQueryPredicates.resourceListAnyByMultipleParentIds(
              query,
              newResources.map((r) => r.parent?.id).filter(isTruthy),
            ),
          type: 'active',
        })
        .map((query) => query.queryKey);

      /**
       * Clears the pending state, so if the user refreshes it will not cause all queries to be invalidated
       */
      const clearPendingState = addPendingMutation(allQueryKeys);

      return {
        optimisticUpdates,
        optimisticUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdates.forEach(({ optimisticallyUpdateFilterAndSearchQueries }) =>
            optimisticallyUpdateFilterAndSearchQueries.resetQueriesData(),
          );
          optimisticUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.resetQueriesData(),
          );
        },
        invalidateQueries: () => {
          optimisticUpdates.forEach(({ optimisticallyUpdateFilterAndSearchQueries }) =>
            optimisticallyUpdateFilterAndSearchQueries.invalidateQueries(),
          );
          optimisticUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.invalidateQueries(),
          );
        },
        clearPendingState,
      };
    },
    /**
     * !!! unused, needs update for v2
     * @deprecated
     */
    replaceResourceInCache: (oldResource: Fdoc, newResource: Fdoc, options?: ReplaceOptions) => {
      const { createIfNotFound = false } = options ?? {};

      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.resourceDetail(q, oldResource.id) ||
          resourceQueryPredicates.resourceListAnyByParentId(q, newResource.parentResourceId),
        type: 'active',
      });

      const optimisticUpdateFilterAndSearchQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: (q) =>
            resourceQueryPredicates.resourceListAnyByParentId(q, newResource.parentResourceId),
          pageUpdater: (page, pageNum) => {
            // if create if not found is true, we will filter the old resource out of the results and add the new one at the start
            // otherwise we just map the old resource to the new one

            if (createIfNotFound) {
              const results = pageNum === 0 ? [newResource, ...page.results] : page.results;

              /**
               * We don't really update totals here because in some cases a race
               * condition is enough to make it add more than wanted to the total
               * which causes the UI to show wrong skeleton loading items.
               */
              return {
                ...page,
                results: results.filter((r) => r.id !== oldResource.id),
              };
            } else {
              const results = page.results.map((r) => (r.id === oldResource.id ? newResource : r));

              return {
                ...page,
                results,
              };
            }
          },
        });

      // const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<Fdoc>(
      //   (q) => resourceQueryPredicates.resource(q, oldResource.id),
      //   () => newResource,
      // );

      return {
        optimisticUpdateFilterAndSearchQueries,
        // optimisticUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateFilterAndSearchQueries.resetQueriesData();
          // optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateFilterAndSearchQueries.invalidateQueries();
          // optimisticUpdateDirectQueries.invalidateQueries();
          queryClient.invalidateQueries({
            predicate: resourceQueryPredicates.summaryAll,
          });
        },
      };
    },
  };
};
