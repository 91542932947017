import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { ResourceDetail, ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { isResourceDetailFolder } from '@/src/modules/resources/utils/resourceDetailGuards';
import { QueryObserverOptions } from '@tanstack/react-query';
import React from 'react';

/**
 * fetches a given folder by id
 *
 * @param queryOptions
 * @returns
 */
export const useQueryFolder = (
  resourceId?: string | null,
  queryOptions?: Partial<QueryObserverOptions<ResourceDetail>>,
) => {
  const queryResourceDetail = useQueryResourceDetail(resourceId, {
    ...queryOptions,
    onFetchSuccess: (resource) => {
      if (!isResourceDetailFolder(resource)) {
        throw new Error('Resource is not a folder');
      }
    },
  });

  const folder = React.useMemo(() => {
    if (queryResourceDetail.resource?.kind === 'folder') {
      return queryResourceDetail.resource as ResourceDetailFolder;
    }
    return null;
  }, [queryResourceDetail.resource]);

  return {
    ...queryResourceDetail,
    folder,
  };
};
