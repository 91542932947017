import {
  ConnectionInfo,
  ConnectionInfoIcon,
  ConnectionInfoImage,
} from '@/src/modules/connections/connections.types';
import { ApiResourceRootSubtype } from '@/src/modules/resources/resources.types';

export const connectionInfoIconFactory = (item: ConnectionInfoIcon): ConnectionInfo => {
  return item;
};

export const connectionInfoImageFactory = (item: ConnectionInfoImage): ConnectionInfo => {
  return item;
};

export const isConnectionInfoIcon = (item: ConnectionInfo): item is ConnectionInfoIcon => {
  return (item as ConnectionInfoIcon).icon !== undefined;
};

export const isConnectionInfoImage = (item: ConnectionInfo): item is ConnectionInfoImage => {
  return (item as ConnectionInfoImage).image !== undefined;
};

export const isDeviceConnectionType = (
  value?: ApiResourceRootSubtype,
): value is Extract<ApiResourceRootSubtype, 'IOS_DEVICE' | 'ANDROID_DEVICE'> => {
  if (!value) return false;
  return ['IOS_DEVICE', 'ANDROID_DEVICE'].includes(value);
};
