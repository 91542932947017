import EnterIcon from '@/public/images/icons/Enter.svg';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import Modal from '@/src/modules/ui/components/Modal';
import { P } from '@/src/modules/ui/components/Typography';
import { ConfirmAlert } from '@/src/store/alerts';
import { isInteractiveElement } from '@/src/utils/elements';
import { DialogDescription } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import isHotkey from 'is-hotkey';

type ConfirmDisplayProps = {
  alert: ConfirmAlert;
};
const ConfirmDisplay: React.FC<ConfirmDisplayProps> = ({ alert }) => {
  const onConfirmEnter = (e: React.KeyboardEvent) => {
    // we check for interactive in case the user is focused on the cancel button and presses enter
    if (!isHotkey('enter', e) || isInteractiveElement(e.target)) return;

    e.preventDefault();
    alert.onConfirm();
  };

  const contentProps = {
    onKeyDown: onConfirmEnter,
    onOpenAutoFocus: () => {
      /**
       * necessary, otherwise the kbd attached to the content doesn't work
       */
      return true;
    },
  };

  /**
   * for some reason, when the content is focused, onConfirmEnter is triggered with e.target being the first button in the dom
   * changing e.g. tab index doesn't work, e.target is the first button even though there's no clear focus
   *
   * Flipping the order of the buttons in the dom and changing the visual order to make this work
   *
   */
  const buttons = (
    <>
      <Modal.Button
        {...alert.confirmButtonProps}
        onClick={alert.onConfirm}
        tabIndex={1}
        style={{ order: 10 }}
      >
        {alert.confirmLabel}{' '}
        <Kbd>
          <EnterIcon />
        </Kbd>
      </Modal.Button>
      <Modal.CancelButton onClick={alert.onCancel} tabIndex={1}>
        {alert.cancelLabel} <Kbd>ESC</Kbd>
      </Modal.CancelButton>
    </>
  );

  return alert.fullScreen ? (
    <Modal open={true} onOpenChange={alert.onCancel}>
      <Modal.Portal>
        <Modal.Content width="full" height="full" dynamicHeight={false} {...contentProps}>
          <Modal.Header padding="sm" variant="default" mobileOnly>
            {alert.title}
          </Modal.Header>
          <VisuallyHidden>
            <DialogDescription>{alert.content}</DialogDescription>
          </VisuallyHidden>
          <Modal.Body padding="sm">{alert.content}</Modal.Body>
          <Modal.Footer gap="lg" variant="fullscreen">
            {buttons}
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  ) : (
    <Modal open={true} onOpenChange={alert.onCancel}>
      <Modal.Portal>
        <Modal.Overlay visibleOnMobileViewport />
        <Modal.Content width="xs" disableMobileFullscreen {...contentProps}>
          <Modal.Body padding="sm" style={{ gap: 20 }}>
            <Modal.Title size="xs" style={{ marginTop: '.125rem' }}>
              {alert.title}
            </Modal.Title>
            <VisuallyHidden>
              <DialogDescription>{alert.content}</DialogDescription>
            </VisuallyHidden>
            <P size="md">{alert.content}</P>

            <Modal.DoubleButtonContainer>{buttons}</Modal.DoubleButtonContainer>
          </Modal.Body>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default ConfirmDisplay;
