import { isInDesktop } from '@/src/hooks/todesktop';
import inNextServer from '@/src/utils/next';
import { Capacitor } from '@capacitor/core';

export const getDevice = (): 'desktop' | 'android' | 'ios' | 'web' | 'server' => {
  if (inNextServer()) return 'server';
  if (isInDesktop()) return 'desktop';
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'ios') return 'ios';
    if (Capacitor.getPlatform() === 'android') return 'android';
  }
  if (window.fabricMobile) {
    if (window.fabricMobile.os === 'iOS') return 'ios';
    if (window.fabricMobile.os === 'Android') return 'android';
  }
  return 'web';
};
