import { useGlobalSearchParamIntent } from '@/src/components/GlobalSearch/useGlobalSearchParamIntent';
import { useAnnouncementCheck } from '@/src/hooks/useAnnouncementCheck';
import { useClearDeprecatedStorageKeys } from '@/src/hooks/useClearDeprecatedStorageKeys';
import useLocalstorageForceCap from '@/src/hooks/useLocalstorageForceCap';
import useVersionCheck from '@/src/hooks/useVersionCheck';
import { useVisualViewportWithGlobalStyle } from '@/src/hooks/useVisualViewport';
import { useInitializeDeviceInfoStore } from '@/src/lib/capacitor/useDeviceInfoStore';
import { SocketIOProvider } from '@/src/lib/socket.io/SocketIOProvider';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useAssistantParamIntent } from '@/src/modules/assistant/hooks/useAssistantParamIntent';
import { useTrackUserNavigationHistory } from '@/src/modules/backNavigation/hooks/useTrackUserNavigationHistory';
import { useDesktopAppShortcuts } from '@/src/modules/desktop/shortcuts/useDesktopKeyboardShortcuts';
import { useKeyboardMapChangeListener } from '@/src/modules/keyboardShortcuts/hooks/useKeyboardMap';
import { isShareIntentPage } from '@/src/modules/mobile/utils/shareIntentGuards';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { useTrackDashboardVisit } from '@/src/services/visit';
import { useModifierKeysListener } from '@/src/store/modifierKeys';
import { useFdocNewParamIntent } from '@/src/views/FdocNew/useFdocNewParamIntent';
import { Keyboard, KeyboardInfo, KeyboardResize } from '@capacitor/keyboard';
import { app, nativeWindow } from '@todesktop/client-core';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { subscribe } from '@todesktop/client-ipc';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createGlobalStyle, css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import useAuthStore from '../hooks/auth';
import { isInMobile, useMobileAppListeners, useShareIntentSetup } from '../hooks/mobile';
import { useRewardful } from '../hooks/rewardful';
import {
  desktopFloatUrls,
  isInDesktop,
  useHandleBroadcast,
  useHandleDeeplink,
} from '../hooks/todesktop';
import { useSetupBulkUploader } from '../lib/bulkUploader/store';
import { useInvalidatePendingMutations } from '../lib/react-query/pending';
import { pick } from '../lib/store';
import { useMutationJoinSpace } from '../modules/spaces/mutations/useMutationJoinSpace';
import { showUpgradePrompt } from '../modules/user/utils/showUpgradePrompt';
import { useWoody } from '../services/woody/woody';
import useInviteStore from '../store/invite';
import { useScreenshotsSync } from '../store/screenshots';
import useUIStore, { setExpandedFdocId, setGlobalSelectionOptions } from '../store/ui';
import inNextServer from '../utils/next';

const GlobalStyle = createGlobalStyle<{ mobileKeyboardHeight: number }>`
:root {
  // set default value for the keyboard height
  ${(p) =>
    p.mobileKeyboardHeight
      ? css`
          // when keyboard is opened, we don't include the env safe area
          // our app has max-height set: calc(100vh - var(--keyboard-height)), see Dashboard.module.scss
          // so when the keyboard is opened, we dont need to include the env variable
          --safe-dashboard-bottom-offset: 0px;
          --keyboard-height: ${p.mobileKeyboardHeight}px;
        `
      : css`
          --safe-dashboard-bottom-offset: env(safe-area-inset-bottom, 0px);
          --keyboard-height: 0px;
        `}

}
`;

const App: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { client } = useWoody();
  const { user, authStatus } = useAuthStore((state) => pick(state, ['user', 'authStatus']));
  useScreenshotsSync();
  useSetupBulkUploader();
  useInvalidatePendingMutations();
  useInitializeDeviceInfoStore();

  useTrackUserNavigationHistory();
  useClearDeprecatedStorageKeys();
  useLocalstorageForceCap();

  useTrackDashboardVisit();
  useVersionCheck();
  useAnnouncementCheck();

  useKeyboardMapChangeListener();

  useModifierKeysListener();
  useFdocNewParamIntent();
  useAssistantParamIntent();
  useGlobalSearchParamIntent();

  useShareIntentSetup();
  useMobileAppListeners();
  useHandleBroadcast();
  useHandleDeeplink();
  useDesktopAppShortcuts({
    runOnce: true,
  });

  useEffect(() => {
    if (isInMobile()) document.body.classList.add('cap-app');
    if (isInMobile('ios')) document.body.classList.add('cap-app-ios');
    if (isInMobile('android')) document.body.classList.add('cap-app-android');
  }, []);

  const router = useRouter();

  useEffect(() => {
    const onPopState = () => {
      // make sure to handle the new URL always if it inludes an expandedFdocId query
      const url = new URL(window.location.href);
      const expandedFdocId = url.searchParams.get('expandedFdocId');

      if (!expandedFdocId) return;

      // need to put the URL because router as yet to update
      router.push(url.pathname + url.search, undefined, { shallow: true });
    };

    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, [router]);

  useEffect(() => {
    if (!router.query.onboarding) return;

    router.push('https://fabric.so/app-welcome');
  }, [router, router.query.onboarding]);

  const {
    mobileKeyboardHeight,
    mobileKeyboardHeightSupported,
    setMobileKeyboardHeight,
    setKeyboardOpen,
    setSupportsPassiveEvents,
    setMobileKeyboardHeightSupported,
  } = useUIStore(
    (state) => ({
      mobileKeyboardHeight: state.mobileKeyboardHeight,
      mobileKeyboardHeightSupported: state.mobileKeyboardHeightSupported,
      setMobileKeyboardHeight: state.setMobileKeyboardHeight,
      setKeyboardOpen: state.setKeyboardOpen,
      setSupportsPassiveEvents: state.setSupportsPassiveEvents,
      setMobileKeyboardHeightSupported: state.setMobileKeyboardHeightSupported,
    }),
    shallow,
  );

  useEffect(() => {
    try {
      // @ts-expect-error - this is a hack to check if the browser supports passive events, so typescript complains
      window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
          get: function () {
            setSupportsPassiveEvents(true);
            return true;
          },
        }),
      );
    } catch (e) {}
  }, [setSupportsPassiveEvents]);

  useEffect(() => {
    if (!isInMobile() || isShareIntentPage()) return;

    const checkSupported = async () => {
      const supported = isInMobile('ios')
        ? (await Keyboard.getResizeMode()).mode === KeyboardResize.None
        : isInMobile('android');

      setMobileKeyboardHeightSupported(supported);
    };

    const handleKeyboardShow = (info: KeyboardInfo) => {
      setMobileKeyboardHeight(info.keyboardHeight);
      setKeyboardOpen(true);

      document.body.dataset.keyboardOpen = 'true';
    };

    const handleKeyboardHide = () => {
      setMobileKeyboardHeight(0);
      setKeyboardOpen(false);

      document.body.dataset.keyboardOpen = 'false';
    };

    checkSupported();

    Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
    Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
    Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
    Keyboard.addListener('keyboardDidHide', handleKeyboardHide);

    return () => {
      Keyboard.removeAllListeners();
    };
  }, [setKeyboardOpen, setMobileKeyboardHeight, setMobileKeyboardHeightSupported]);

  const { listId, clearListId } = useInviteStore((s) => ({
    listId: s.listId,
    clearListId: s.clearListId,
  }));

  const storedSearchQuery = useUIStore((s) => s.searchQuery, shallow);
  const storedSearchQueryRef = useRef<string>(storedSearchQuery);
  const { track } = useAnalytics();

  const currentSearchPlace = useMemo(() => {
    if (router.pathname === '/spaces/[listId]') return 'space';
    if (router.pathname === '/timeline') return 'timeline';
    return 'everything';
  }, [router.pathname]);

  useEffect(() => {
    if (storedSearchQuery === storedSearchQueryRef.current) return;

    const debounce = setTimeout(() => {
      storedSearchQueryRef.current = storedSearchQuery;

      track(AnalyticsEvents.Search, {
        has_query: !!storedSearchQuery,
        action: currentSearchPlace,
      });
    }, 3000);

    return () => clearTimeout(debounce);
  }, [storedSearchQuery, currentSearchPlace, track, user?.id]);

  const { mutate: mutateJoinSpace } = useMutationJoinSpace();

  useEffect(() => {
    if (!listId || !user?.id || authStatus !== 'authenticated') return;

    const joinList = async (listId: string) => {
      mutateJoinSpace(
        { spaceId: listId, role: 'viewer', action: 'login-or-signup-from-space-invite' },
        {
          onSettled: () => {
            clearListId();
          },
        },
      );
    };

    joinList(listId);
  }, [clearListId, client, listId, router, user?.id, authStatus, mutateJoinSpace]);

  const isDesktopFloat = desktopFloatUrls.includes(router.pathname);
  const wasEverDesktopFloat = useRef<boolean>(isDesktopFloat);

  useEffect(() => {
    if ((!isInDesktop() && !isInMobile()) || !user) return;

    // check if the localStorage 'isFirstRun' is set to true
    // if it is then we do a analytics track for install
    const isFirstRun = localStorage.getItem('isFirstRun');
    if (isFirstRun === 'true') return;

    // if it is not set to true, then we set it to true and track the install
    localStorage.setItem('isFirstRun', 'true');

    if (isInDesktop()) track(AnalyticsEvents.InstalledDesktop);
    else if (isInMobile('ios')) track(AnalyticsEvents.InstallediOS);
    else if (isInMobile('android')) track(AnalyticsEvents.InstalledAndroid);
  }, [track, user]);

  const handleOpenFdoc = useCallback(async (fdocId: string | null) => {
    if (!fdocId || !isDesktopApp()) return;

    await nativeWindow.focus();
    await nativeWindow.maximize();

    setExpandedFdocId(fdocId);
  }, []);

  const handleOpenPath = useCallback(
    async (path: string | null) => {
      if (!path || !isDesktopApp()) return;

      await nativeWindow.focus();

      await router.push(
        {
          pathname: path,
        },
        undefined,
        { shallow: true },
      );
    },
    [router],
  );

  const handleAddToSpace = useCallback(async (fdocId?: string | null) => {
    if (!fdocId || !isDesktopApp()) return;
    await nativeWindow.focus();
    await nativeWindow.maximize();

    setGlobalSelectionOptions({
      selectedFdocsIds: [fdocId],
    });
  }, []);

  const handleOpenHome = useCallback(async () => {
    if (!isDesktopApp()) return;

    await nativeWindow.focus();
    await nativeWindow.maximize();

    await router.push('/', undefined, { shallow: true });
  }, [router]);

  useEffect(() => {
    if (!isDesktopApp() || isDesktopFloat || !user) return;
    let unsubscribeOpenFdoc: () => void;
    let unsubscribeOpenSpace: () => void;
    let unsubscribeAddToSpace: () => void;
    let unsubscribeOpenHome: () => void;
    let unsubscribeError: () => void;

    try {
      unsubscribeOpenFdoc = subscribe('open-fdoc', (_data: unknown) => {
        const data = _data as { resource: ResourceDetail; list?: string };

        handleOpenFdoc(data.resource.id);
      });
    } catch (e) {
      console.error("Unable to subscribe to 'open-fdoc' event", e);
    }

    try {
      unsubscribeOpenSpace = subscribe('open-path', (_data: unknown) => {
        const data = _data as { path: string };

        handleOpenPath(data.path);
      });
    } catch (e) {
      console.error("Unable to subscribe to 'open-path' event", e);
    }

    try {
      unsubscribeAddToSpace = subscribe('add-to-space', (_data: unknown) => {
        const data = _data as { resource: ResourceDetail };

        handleAddToSpace(data.resource.id);
      });
    } catch (e) {
      console.error("Unable to subscribe to 'add-to-space' event", e);
    }

    try {
      unsubscribeOpenHome = subscribe('open-home', () => {
        handleOpenHome();
      });
    } catch (e) {
      console.error("Unable to subscribe to 'open-home' event", e);
    }

    try {
      unsubscribeError = subscribe('error', (data: string) => {
        nativeWindow.focus();
        showUpgradePrompt(data);
      });
    } catch (e) {
      console.error("Unable to subscribe to 'error' event", e);
    }

    return () => {
      unsubscribeOpenFdoc?.();
      unsubscribeOpenSpace?.();
      unsubscribeAddToSpace?.();
      unsubscribeOpenHome?.();
      unsubscribeError?.();
    };
  }, [
    isDesktopFloat,
    user,
    router,
    handleOpenFdoc,
    handleOpenPath,
    handleAddToSpace,
    handleOpenHome,
  ]);

  useEffect(() => {
    let unsubscribe: () => void;
    let unloaded = false;

    app
      .on('openProtocolURL', (_, e) => {
        if (!e.url) return;

        try {
          const url = new URL(e.url);
          // check if it is open-fdoc
          if (
            url.pathname !== '/open-fdoc' &&
            url.pathname !== '/open-path' &&
            url.pathname !== '/add-to-space' &&
            url.pathname !== '/home'
          )
            return;
          e.preventDefault();

          switch (url.pathname) {
            case '/open-fdoc': {
              const resourceId = url.searchParams.get('id');
              handleOpenFdoc(resourceId);
              break;
            }
            case '/open-path':
              const path = url.searchParams.get('path');
              handleOpenPath(path);
              break;
            case '/add-to-space': {
              const resourceId = url.searchParams.get('id');
              handleAddToSpace(resourceId);
              break;
            }
            case '/home':
              handleOpenHome();
              break;
          }
        } catch (e) {
          console.error('Unable to open protocol url', e);
        }
      })
      .then((unsub) => {
        unsubscribe = unsub;
        if (unloaded) unsub();
      })
      .catch((e) => {
        console.error('Unable to subscribe to openProtocolURL', e);
      });

    return () => {
      unloaded = true;
      if (!unsubscribe) return;
      unsubscribe();
    };
  }, [
    isDesktopFloat,
    user,
    router,
    handleOpenFdoc,
    handleOpenPath,
    handleAddToSpace,
    handleOpenHome,
  ]);

  useEffect(() => {
    if (
      !wasEverDesktopFloat.current ||
      !isDesktopApp() ||
      inNextServer() ||
      !user ||
      !router.isReady
    )
      return;

    if (!desktopFloatUrls.includes(router.pathname)) {
      nativeWindow.close();
    } else {
      // if we are in a floating URL, we need to focus the window steal and maximize it
      nativeWindow.focus();
    }
  }, [router.isReady, router.pathname, user]);

  const routerBackRef = useRef(router.back);
  useEffect(() => {
    routerBackRef.current = router.back;
  }, [router.back]);

  useRewardful();

  const VisualViewportGlobalStyle = useVisualViewportWithGlobalStyle();

  return (
    <SocketIOProvider>
      {VisualViewportGlobalStyle}
      <GlobalStyle
        mobileKeyboardHeight={mobileKeyboardHeightSupported ? mobileKeyboardHeight : 0}
      />
      {children}
    </SocketIOProvider>
  );
};

export default App;
