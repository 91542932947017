import { isObject } from '@/src/lib/utils';
import {
  FileShareIntent,
  RawShareIntent,
  ShareIntent,
  ShareIntentType,
  TextShareIntent,
  UrlShareIntent,
} from '@/src/modules/mobile/mobile.types';

export const isUrlShareIntent = (intent: ShareIntent): intent is UrlShareIntent => {
  return intent.type === ShareIntentType.URL;
};

export const isTextShareIntent = (intent: ShareIntent): intent is TextShareIntent => {
  return intent.type === ShareIntentType.TEXT;
};

export const isFileShareIntent = (intent: ShareIntent): intent is FileShareIntent => {
  return intent.type === ShareIntentType.FILE;
};

export const isRawShareIntent = (intent: unknown): intent is RawShareIntent => {
  return isObject(intent) && 'type' in intent && typeof intent.type === 'string';
};

export const isShareIntentPage = () => window.location.pathname === '/share-intent';
