import {
  ResourceDetail,
  ResourceDetailAudio,
  ResourceDetailBookmark,
  ResourceDetailDefault,
  ResourceDetailDocument,
  ResourceDetailFolder,
  ResourceDetailHighlight,
  ResourceDetailImage,
  ResourceDetailNotepad,
  ResourceDetailVideo,
} from '@/src/modules/resources/resources.types';

export const isResourceDetailImage = (detail: ResourceDetail): detail is ResourceDetailImage =>
  detail.kind === 'image';
export const isResourceDetailDocument = (
  detail: ResourceDetail,
): detail is ResourceDetailDocument => detail.kind === 'document';
export const isResourceDetailBookmark = (
  detail: ResourceDetail,
): detail is ResourceDetailBookmark => detail.kind === 'bookmark';
export const isResourceDetailImageBookmark = (
  detail: ResourceDetail,
): detail is ResourceDetailImage => detail.kind === 'image' && !!detail.originUrl;
export const isResourceDetailFolder = (detail: ResourceDetail): detail is ResourceDetailFolder =>
  detail.kind === 'folder';
export const isResourceDetailHighlight = (
  detail: ResourceDetail,
): detail is ResourceDetailHighlight => detail.kind === 'highlight';
export const isResourceDetailNotepad = (detail: ResourceDetail): detail is ResourceDetailNotepad =>
  detail.kind === 'notepad';
export const isResourceDetailDefault = (detail: ResourceDetail): detail is ResourceDetailDefault =>
  detail.kind === 'default';
export const isResourceDetailAudio = (detail: ResourceDetail): detail is ResourceDetailAudio =>
  detail.kind === 'audio';
export const isResourceDetailVideo = (detail: ResourceDetail): detail is ResourceDetailVideo =>
  detail.kind === 'video';

export const isResourceIntegrationItem = (detail: ResourceDetail): boolean =>
  detail.root?.type === 'INTEGRATION';
