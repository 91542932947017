import { CODE_KEY_MAP } from '@/src/modules/keyboardShortcuts/codeKeyMap';
import { hasMacOSKbd, isWindowsPlatform } from '@/src/types/global';

export const modifierKeys = ['control', 'ctrl', 'shift', 'alt', 'meta', 'cmd'];

export type ModifierKey = (typeof modifierKeys)[number];

const isMac = hasMacOSKbd();

export const keyToDisplayValue = (item: string) => {
  switch (item.toLowerCase()) {
    case 'ctrl':
    case 'control':
      return hasMacOSKbd() ? '⌃' : 'ctrl';
    case 'alt':
      return isMac ? '⌥' : 'alt';
    case 'shift':
      return '⇧';
    case 'meta':
      return isWindowsPlatform() ? 'win' : hasMacOSKbd() ? '⌘' : 'meta';
    default: {
      const value = CODE_KEY_MAP[item.toLowerCase()] || item;
      return value.length === 1 ? value.toUpperCase() : value;
    }
  }
};

export const shortKeysToDisplayValue = (keys: string[]) => {
  return keys
    .map((item) => {
      switch (item.toLowerCase()) {
        case 'ctrl':
          return hasMacOSKbd() ? '⌃' : 'ctrl';
        case 'alt':
          return isMac ? '⌥' : 'alt';
        case 'shift':
          return '⇧';
        case 'meta':
          return isWindowsPlatform() ? 'win' : hasMacOSKbd() ? '⌘' : 'meta';
        default: {
          const value = CODE_KEY_MAP[item.toLowerCase()] || item;
          return value.length === 1 ? value.toUpperCase() : value;
        }
      }
    })
    .join(' + ');
};
