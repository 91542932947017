/**
 * This file is kept for backwards compatibility, this plugin was removed but this allows access to the functionality for old app versions.
 * https://github.com/carsten-klaffke/send-intent/tree/master/src/index.ts
 */

import { registerPlugin } from '@capacitor/core';
import type { SendIntentPlugin } from './definitions';

const SendIntent = registerPlugin<SendIntentPlugin>('SendIntent', {
  web: () => import('./web').then((m) => new m.SendIntentWeb()),
});

export * from './definitions';
export { SendIntent };
