import { QueryKey } from '@tanstack/react-query';
import {
  QueryResourceListContextSearchParams,
  QueryResourceListParams,
  ResourceDetail,
} from '../resources.types';
import { isResourceListContextSearchQueryKey, isResourceListQueryKey } from './queryKeyGuards';

// type ResourceFilterType = 'webnote' | 'notepad' | 'file' | 'folder';
// family?: 'highlight' | 'page' | 'generic_file' | 'image' | 'notepad';
// ResourceType = 'page' | 'image' | 'text' | 'notepad' | 'stored_file' | 'folder';

const filterOptionsTypeToResourceTypes = (type: string) => {
  switch (type) {
    case 'webnote':
      return ['page', 'text', 'image'];
    case 'notepad':
      return ['notepad'];
    case 'file':
      return ['stored_file'];
    case 'folder':
      return ['folder'];
    default:
      return [];
  }
};

const resourceMatchesFamilyFilter = (resource: ResourceDetail, family: string): boolean => {
  switch (family) {
    case 'highlight':
      return ['bookmark', 'text', 'image'].includes(resource.kind);
    case 'page':
      return ['bookmark'].includes(resource.kind);
    case 'notepad':
      return ['notepad'].includes(resource.kind);
    case 'image':
      return resource.kind === 'image';
    case 'generic_file':
      return ['video', 'audio', 'default', 'document'].includes(resource.kind);
  }
  return false;
};

const resourceMatchesFilterQuery = (
  resource: ResourceDetail,
  options?: QueryResourceListParams,
): boolean => {
  if (!options) return true;

  if (options.label && options.label !== options.label) {
    return false;
  }

  if (options.rootId && options.rootId !== resource.root?.id) {
    return false;
  }

  if (
    options.tagIds &&
    !options.tagIds.some((tag) => resource.tags.some((resourceTag) => resourceTag.id === tag))
  ) {
    return false;
  }

  if (options.kind && !options.kind.includes(resource.kind)) {
    return false;
  }

  return true;
};

const resourceMatchesSearchQuery = (
  resource: ResourceDetail,
  options?: QueryResourceListContextSearchParams,
): boolean => {
  if (!options) return true;

  if (
    options.filters?.family &&
    !options.filters.family.some((family) => resourceMatchesFamilyFilter(resource, family))
  )
    return false;
  if (
    options.filters?.tagIds &&
    !options.filters?.tagIds?.some((tag) =>
      resource.tags.some((resourceTag) => resourceTag.id === tag),
    )
  )
    return false;
  if (
    options.filters?.type &&
    !options.filters.type.flatMap(filterOptionsTypeToResourceTypes).includes(resource.kind)
  )
    return false;
  if (
    options.filters?.rootId &&
    !options.filters?.rootId?.some((list) => resource.root?.id === list)
  )
    return false;
  if (
    options.filters?.label &&
    !options.filters.label.some((label) => resource.label?.id === label)
  )
    return false;

  return true;
};

/**
 * Given a resource and a query key, determine if the resource matches the query filters.
 * This might be heavy so use with caution, this is useful for optimistic created resources which we want to avoid going into the wrong cached queries.
 */
export const resourceMatchesQueryFilters = (resource: ResourceDetail, queryKey: QueryKey) => {
  if (isResourceListQueryKey(queryKey) && !!queryKey[1]) {
    return resourceMatchesFilterQuery(resource, queryKey[1]);
  }

  if (isResourceListContextSearchQueryKey(queryKey) && !!queryKey[1]) {
    return resourceMatchesSearchQuery(resource, queryKey[1]);
  }

  return false;
};
