import {
  FabricMobileAsyncCallback,
  FabricMobileAsyncCallbackRaw,
  FabricMobileAsyncFns,
} from '@/@types/fabric';
import { isString } from '@/src/lib/utils';
import { isTruthy } from '@/src/utils/guards';

function generateCallback<T extends keyof FabricMobileAsyncFns>(
  cb: FabricMobileAsyncCallback<T>,
): FabricMobileAsyncCallbackRaw {
  const chunks: string[] = [];

  return (success, totalChunks, chunkIndex, chunkData) => {
    if (!success) {
      cb(false, chunkData);
      return;
    }

    chunks[chunkIndex] = chunkData;

    if (chunks.filter(isTruthy).length === totalChunks) {
      const fullData = chunks.join('');
      const result = JSON.parse(fullData) as FabricMobileAsyncFns[T]['return'];
      cb(true, result);
    }
  };
}

async function fabricMobileAsync<T extends keyof FabricMobileAsyncFns>(
  funcName: T,
  params: FabricMobileAsyncFns[T]['params'],
): Promise<FabricMobileAsyncFns[T]['return']> {
  if (!window.fabricMobile) throw new Error('Not in a valid Fabric mobile page.');
  if (!window.fabricMobile.async) window.fabricMobile.async = {};

  const callbackId = window.fabricMobile.runAsync(funcName, JSON.stringify(params));

  return new Promise((resolve, reject) => {
    if (!window.fabricMobile) return reject(new Error('Not in a valid Fabric mobile page.'));

    window.fabricMobile.async[callbackId] = generateCallback((success, result) => {
      if (!success || isString(result))
        return reject(isString(result) ? new Error(result) : result);
      resolve(result);
    });
  });
}

export { fabricMobileAsync };
