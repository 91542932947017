import { AndroidInsets, GestureExclusion } from '@/mobile/GestureExclusion';
import { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { isInMobile } from './mobile';

const AndroidGlobalStyles = createGlobalStyle<{ insets?: AndroidInsets }>`
:root {
  ${(p) => {
    return p.insets
      ? `
    --android-inset-top: ${p.insets.top}px;
    --android-inset-bottom: ${p.insets.bottom}px;
    --android-inset-left: ${p.insets.left}px;
    --android-inset-right: ${p.insets.right}px;
    `
      : '';
  }}
  }
`;

export const useAndroidInsets = (disabled = false) => {
  const [insets, setInsets] = useState<AndroidInsets | null>(null);

  useEffect(() => {
    if (!isInMobile('android') || disabled) return;

    GestureExclusion.getInsets().then(setInsets);
    const event = GestureExclusion.addListener('onInsetsChange', setInsets);

    return () => {
      Promise.resolve(event).then((e) => e.remove());
    };
  }, [disabled]);

  return {
    insets,
    AndroidGlobalStyles: <AndroidGlobalStyles insets={insets ?? undefined} />,
  };
};
