import useAuthStore from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import { NewResource } from '@/src/modules/resources/createResource.types';
import { resourceMutationKeys } from '@/src/modules/resources/mutations/resourceMutationKeys';
import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import { QueryResourceListDataState } from '@/src/modules/resources/queries/useQueryResourceList';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { createOptimisticResource } from '@/src/modules/resources/utils/createOptimisticResource';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { OptimisticDraft } from '@/src/types/draftable';
import { captureException } from '@sentry/nextjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export type ResourceCreationActions =
  | 'new-modal'
  | 'desktop-quick-note'
  | 'sidebar'
  | 'folder-header'
  | 'inbox-header'
  | 'space-header'
  | 'new-folder-modal'
  | 'list-context-menu'
  | 'bulk-upload';

type MutationVariables = {
  data: NewResource;
  disableToast?: boolean;
  action?: ResourceCreationActions;
};

const resourceTypeToChecklistActionId = (type: ResourceDetail['kind']) => {
  switch (type) {
    case 'image':
    case 'audio':
    case 'document':
    case 'video':
    case 'default':
      return OnboardingCheckListActionId.UPLOAD_FIRST_FILE;
    case 'notepad':
      return OnboardingCheckListActionId.CREATE_FIRST_NOTEPAD;
    case 'bookmark':
      return OnboardingCheckListActionId.SAVE_FIRST_LINK;
    case 'folder':
      return null;
    default: {
      console.error('Unknown resource type to onboarding checklist', type);
      return null;
    }
  }
};

const getFailureMessageByCreateType = (createType: NewResource['createType']) => {
  switch (createType) {
    case 'note':
      return 'Failed to create note';
    case 'file':
      return 'Failed to upload file';
    case 'bookmark':
      return 'Failed to save bookmark';
    case 'folder':
      return 'Failed to create folder';
    default:
      return 'Failed to create';
  }
};

/**
 * map so it's typed when passing to client.v2
 */
const createDataToPathBody = ({ createType, body }: NewResource) => {
  switch (createType) {
    case 'note':
      return {
        path: '/v2/notepads' as const,
        body,
      };
    case 'file': {
      const nextBody = {
        ...body,
      };

      /**
       * we don't want to include the name here
       * BE automatically sets it from the attachment.filename
       */
      delete nextBody.name;

      return {
        path: '/v2/files' as const,
        body: nextBody,
      };
    }
    case 'bookmark':
      return {
        path: '/v2/bookmarks' as const,
        body,
      };
    case 'folder': {
      console.warn('Creating folder might break, waiting for endpoint update');
      return {
        path: '/v2/folders' as const,
        body,
      };
    }
    default: {
      throw new Error(`Unknown createType ${createType}`);
    }
  }
};

export const useMutationCreateResourceV2 = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();
  const { addNewResourcesToCache } = useQueryCacheResourceHelpers();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: resourceMutationKeys.createResource,
    mutationFn: async ({ data }: MutationVariables): Promise<ResourceDetail> => {
      const { path, body } = createDataToPathBody(data);
      const resource = (await client.v2(path, {
        method: 'post',
        body,
      })) as ResourceDetail;

      if (!resource) {
        throw new Error('Failed to create resource');
      }

      return resource;
    },
    onMutate: async ({ data }) => {
      const optimisticResource = createOptimisticResource(data, useAuthStore.getState().user!);
      return {
        optimisticResource,
        addResourceCache: addNewResourcesToCache([optimisticResource]),
      };
    },
    onSuccess: (newResource, { disableToast, action = 'new-modal' }, { optimisticResource }) => {
      const parentId = newResource.parent?.id;
      /**
       * update cache - we preserve the draftId which is used as component key
       */
      if (parentId) {
        queryClient.setQueriesData<QueryResourceListDataState>(
          {
            predicate: (q) => resourceQueryPredicates.resourceListAnyByParentId(q, parentId),
          },
          (prevData) => {
            if (!prevData) {
              return prevData;
            }

            const next = {
              ...prevData,
              pages: prevData.pages.map((page) => {
                return {
                  ...page,
                  resources: page.resources.map((resource) => {
                    /**
                     * we replace the draft with server response data but keeping the draft id
                     * draft id is used as react key to render components.
                     * preserving it will prevent re-rendering of the components (need to use key={resource.draftId || resource.id})
                     */

                    /**
                     * preserving the bookmark image
                     */
                    if ('draftId' in resource && resource.draftId === optimisticResource.draftId) {
                      let nextData: ResourceDetail['data'] = newResource.data;
                      if (
                        optimisticResource.kind === 'bookmark' &&
                        newResource.kind === 'bookmark' &&
                        newResource.data &&
                        'webpage' in newResource.data &&
                        optimisticResource.data &&
                        'webpage' in optimisticResource.data
                      ) {
                        nextData = {
                          ...resource.data,
                          webpage: {
                            ...newResource.data.webpage,
                            image: {
                              ...optimisticResource.data.webpage?.image,
                              ...newResource.data.webpage?.image,
                              url:
                                optimisticResource.data.webpage?.image?.url ||
                                newResource.data.webpage?.image?.url,
                            },
                          },
                        };
                      }

                      const updatedCacheItem = {
                        ...newResource,
                        draftId: resource.draftId,
                        /**
                         * we want to keep thumbnail of the optimistic resource if available
                         * to prevent flashing and re-loading of the thumbnail
                         */
                        thumbnail: optimisticResource.thumbnail || newResource.thumbnail,
                        data: nextData,
                      } as OptimisticDraft<ResourceDetail>;
                      delete updatedCacheItem.isDraft;
                      return updatedCacheItem;
                    }
                    return resource;
                  }),
                };
              }),
            };

            return next;
          },
        );
      }

      const actionId = resourceTypeToChecklistActionId(newResource.kind);

      if (actionId) {
        mutateCheckListItemState({
          actionId,
          state: true,
        });
      }

      if (!disableToast) {
        toast({
          id: 'create-new-resource',
          replace: true,
          content: (
            <span>
              <span style={{ textTransform: 'capitalize' }}>{newResource.kind}</span>
              {' created'}
            </span>
          ),
        });
      }

      track(AnalyticsEvents.CreatedFdoc, {
        type: newResource.kind,
        action,
      });

      window.dispatchEvent(
        new CustomEvent(
          newResource.kind === 'folder' ? 'fabric-folder-created' : 'fabric-resource-created',
          {},
        ),
      );
    },
    onError(error, variables, context) {
      captureException(new Error('Failed to create resource'), {
        extra: {
          error,
          variables,
        },
      });

      if (context) {
        context.addResourceCache.resetCacheToPreOptimisticState();
      }

      toast({
        id: 'create-new-resource-failure',
        replace: true,
        content: getFailureMessageByCreateType(variables.data.createType),
      });
    },
    onSettled: (_r, _e, _rs, context) => {
      context?.addResourceCache.clearPendingState();
    },
  });

  return mutation;
};
