import { NewResource } from '@/src/modules/resources/createResource.types';
import {
  FabricMimeTypes,
  ResourceDetail,
  ResourceDetailBase,
  ResourceDetailBookmark,
  ResourceDetailFolder,
  ResourceDetailImage,
  ResourceDetailNotepad,
} from '@/src/modules/resources/resources.types';
import { ApiUserMe } from '@/src/modules/user/user.types';
import { OptimisticDraft } from '@/src/types/draftable';
import { getExtensionFromFileName } from '@/src/utils/getExtensionFromFileName';
import { v4 } from 'uuid';

const getMimeTypeByNewResourceData = (data: NewResource): FabricMimeTypes | string => {
  switch (data.createType) {
    case 'bookmark':
      return FabricMimeTypes.VND_FABRIC_BOOKMARK;
    case 'folder':
      return FabricMimeTypes.VND_FABRIC_FOLDER;
    case 'note':
      return FabricMimeTypes.VND_FABRIC_NOTEPAD;
    case 'file':
      return data.body.mimeType;
  }
};

export const createOptimisticResource = (
  newResource: NewResource,
  user: ApiUserMe,
  options?: {
    id?: string;
  },
): OptimisticDraft<ResourceDetail> => {
  /**
   * non-null assertion operator, we don't expect this to be called when user is null
   */

  const optimisticResourceId = options?.id || v4();
  const createdModifiedAt = new Date().toISOString();

  const base = {
    id: optimisticResourceId,
    draftId: optimisticResourceId,
    isDraft: true as const,
    name: newResource.body.name || '',
    mimeType: getMimeTypeByNewResourceData(newResource),
    stateProcessing: 'pending' as const,
    originUrl: ('url' in newResource.body && newResource.body.url) || null,
    isDirectShared: false,
    isShared: false,
    isReadonly: false,
    tags: 'tags' in newResource.optimisticData ? newResource.optimisticData.tags : [],
    createdAt: createdModifiedAt,
    modifiedAt: createdModifiedAt,
    user,
    parent: newResource.optimisticData.parent,
    label: null,
    isHidden: false,
    ancestors: [],
  };

  /**
   * comment related
   */

  const commentRelatedProperties: Pick<ResourceDetailBase, 'commentPinned' | 'commentCount'> =
    'comment' in newResource.body &&
    (newResource.body.comment?.annotation || newResource.body.comment?.content)
      ? {
          commentPinned: {
            // TODO draft comment
            id: v4(),
            user,
            content: newResource.body.comment.content || '',
            annotation: newResource.body.comment.annotation || null,
            createdAt: createdModifiedAt,
            modifiedAt: createdModifiedAt,
            resourceId: optimisticResourceId,
          },
          commentCount: 1,
        }
      : {
          commentPinned: null,
          commentCount: 0,
        };

  /**
   * file related
   */
  const fileRelatedProperties: Pick<
    ResourceDetailBase,
    'extension' | 'size' | 'thumbnail' | 'fileUrl' | 'mimeType'
  > =
    newResource.createType === 'file'
      ? {
          size: newResource.optimisticData.file.size,
          extension: getExtensionFromFileName(newResource.optimisticData.file.name),
          thumbnail: null, // TODO
          fileUrl: newResource.body.attachment.filename,
          mimeType: newResource.optimisticData.file.type,
        }
      : {
          extension: null,
          size: null,
          thumbnail: null,
          fileUrl: null,
          mimeType: null,
        };

  const baseResource: OptimisticDraft<ResourceDetailBase> = {
    ...base,
    ...fileRelatedProperties,
    ...commentRelatedProperties,
    jobsProcessing: {
      attributes: 'pending',
    },
  };

  /**
   * folder kind
   */
  if (newResource.createType === 'folder') {
    const result: OptimisticDraft<ResourceDetailFolder> = {
      ...baseResource,
      kind: 'folder',
      mimeType: FabricMimeTypes.VND_FABRIC_FOLDER,
      data: {},
      stateProcessing: 'completed',
    };

    return result;
  }

  /**
   * file kind
   */
  if (newResource.createType === 'file') {
    const baseData = {
      infected: false,
      summary: null,
      attributes: null,
      extensionContext: undefined,
      webpage: null,
      ocr: null,
      caption: null,
    };

    /**
     * image
     */
    const thumbnailUrl = newResource.optimisticData.thumbnailUrl;
    if (newResource.optimisticData.file.type.startsWith('image/')) {
      const result: OptimisticDraft<ResourceDetailImage> = {
        ...baseResource,
        kind: 'image',
        data: baseData,
        thumbnail: thumbnailUrl
          ? {
              sm: thumbnailUrl,
              md: thumbnailUrl,
              lg: thumbnailUrl,
              xl: thumbnailUrl,
              original: thumbnailUrl,
            }
          : null,
      };
      return result;
    }

    /**
     * other
     */
    return {
      ...baseResource,
      kind: 'default',
      data: baseData,
    };
  }

  /**
   * notepad kind
   */
  if (newResource.createType === 'note') {
    const result: OptimisticDraft<ResourceDetailNotepad> = {
      ...baseResource,
      kind: 'notepad',
      mimeType: FabricMimeTypes.VND_FABRIC_NOTEPAD,
      data: {
        attributes: {
          author: user.name || 'You',
        },
        preview: {
          content: newResource.optimisticData.preview.content || '',
        },
      },
    };

    return result;
  }

  /**
   * bookmark
   */
  if (newResource.createType === 'bookmark') {
    const result: OptimisticDraft<ResourceDetailBookmark> = {
      ...baseResource,
      kind: 'bookmark',
      mimeType: FabricMimeTypes.VND_FABRIC_BOOKMARK,
      data: {
        attributes: {
          pageTitle: newResource.body.name || 'Untitled',
          pageUrl: newResource.body.url,
        },
        extensionContext: null,
        webpage: newResource.optimisticData.linkPreview
          ? {
              ...newResource.optimisticData.linkPreview,
              html: null,
              reader: null,
              metadata: newResource.optimisticData.linkPreview.metadata as any,
              screenshot: null,
              image: newResource.optimisticData.linkPreview.image
                ? {
                    ...newResource.optimisticData.linkPreview.image,
                    mime: null,
                  }
                : null,
              favicon: newResource.optimisticData.linkPreview.favicon
                ? {
                    ...newResource.optimisticData.linkPreview.favicon,
                    mime: null,
                  }
                : null,
            }
          : null,
      },
      thumbnail: newResource.optimisticData.linkPreview?.image
        ? {
            sm: newResource.optimisticData.linkPreview.image.url,
            md: newResource.optimisticData.linkPreview.image.url,
            lg: newResource.optimisticData.linkPreview.image.url,
            xl: newResource.optimisticData.linkPreview.image.url,
            original: newResource.optimisticData.linkPreview.image.url,
          }
        : null,
    };
    return result;
  }

  throw new Error('Invalid resource type to create optimistic resource');
};
