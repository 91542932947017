'use client';

import BackIcon from '@/public/images/icons/Back.svg';
import { handleViewportCSS } from '@/src/hooks/useVisualViewport';
import CloseIcon from '@/src/icons/CloseIcon';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled, { css, keyframes } from 'styled-components';
import { mediaMobile, mediaNotMobile } from '../styled-utils';
import { CssBackgroundProp, getCssBackground } from '../theme/cssBackground';
import { cssVar } from '../theme/variables';
import { Button } from './Button';

export interface ModalControls {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

// @TODO Normalize Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  from {
    transform: translate3d(var(--modal-transform-x), var(--modal-transform-y), 0) scale(0.95);
  }
  to {
    transform: translate3d(var(--modal-transform-x), var(--modal-transform-y), 0) scale(1);
}
`;

const scaleOut = keyframes`
  from {
    transform: translate3d(var(--modal-transform-x), var(--modal-transform-y), 0) scale(1);
  }
  to {
    transform: translate3d(var(--modal-transform-x), var(--modal-transform-y), 0) scale(0.95);
  }
`;

const ModalRoot = DialogPrimitive.Root;

const ModalTrigger = DialogPrimitive.Trigger;

const ModalPortal = DialogPrimitive.Portal;

const ModalOverlay = styled(DialogPrimitive.Overlay).withConfig(
  preventForwardPropsConfig(['visibleOnMobileViewport', 'noMobileBlur']),
)<{
  visibleOnMobileViewport?: boolean;
  noMobileBlur?: boolean;
}>`
  position: fixed;
  inset: 0;
  z-index: 125;
  background-color: ${cssVar['color-bg-overlay']};
  will-change: opacity;

  /* &[data-state='open'] {
    animation: ${fadeIn} 0.2s ease-out;
  } */

  &[data-state='closed'] {
    animation: ${fadeOut} 0.2s ease-out;
  }

  ${mediaMobile} {
    ${(p) =>
      !p.visibleOnMobileViewport &&
      css`
        display: none;
      `};

    ${(p) =>
      p.noMobileBlur &&
      css`
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        background-color: ${cssVar['color-bg-quinary']};
      `};
  }
`;

const modalWidths = {
  xs: '400px',
  sm: '450px',
  md: '640px',
  lg: '840px',
  full: '100%',
};

const modalHeights = {
  sm: '50vh',
  md: '70vh',
  lg: '90vh',
  full: '100%',
};

const modalContentGaps = {
  none: '0',
  xs: '5px',
  sm: '10px',
  md: '20px',
  lg: '30px',
};

interface ModalContentProps {
  width?: keyof typeof modalWidths;
  height?: keyof typeof modalHeights;
  gap?: keyof typeof modalContentGaps;

  // this defines if the modal will grow with it's content or always expand to fill
  // width and height
  dynamicWidth?: boolean;
  dynamicHeight?: boolean;

  disableMobileFullscreen?: boolean;

  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  alignItems?: 'flex-start' | 'center' | 'flex-end';

  respectMobileKeyboard?: boolean;
}

export const defaultModalContentProps = {
  onOpenAutoFocus: (e: Event) => e.preventDefault(),
  onCloseAutoFocus: (e: Event) => e.preventDefault(),
  'aria-label': 'modal',
};

const ModalContent = styled(DialogPrimitive.Content)
  .attrs<ModalContentProps>((props) => {
    return {
      ...defaultModalContentProps,
      ...props,
    };
  })
  .withConfig(preventForwardPropsConfig(['respectMobileKeyboard']))`
  position: fixed;
  max-width: 640px;
  z-index: 125;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  border: 1px solid ${cssVar['color-border-primary']};
  background: ${cssVar['color-bg-primary']};
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.15s ease;
  transition-delay: 0;
  will-change: transform, opacity, height;
  height: max-content;
  max-height: 90vh;
  overflow: hidden;
  outline: none !important;

  justify-content: ${({ justifyContent }) => justifyContent ?? 'initial'};
  align-items: ${({ alignItems }) => alignItems ?? 'initial'};

  // the 0.05px is a hack to prevent the text looking blurry on Chrome-based browsers
  --modal-transform-x: calc(-50% - 0.05px);
  --modal-transform-y: -50%;

  left: 50%;
  top: calc(50% - var(--keyboard-height, 0px) / 3);
  transform: translate(var(--modal-transform-x), var(--modal-transform-y));

  border-radius: ${({ width, height }) => (width === 'full' && height === 'full' ? 0 : 20)}px;

  box-shadow: 0px 4px 100px 0px rgba(var(--fabric-color-bg-primary-reverse-rgb), 0.05);

  &[data-state='open'] {
    animation:
      ${fadeIn} 0.2s ease-out,
      ${scaleIn} 0.2s ease-out;
  }

  &[data-state='closed'] {
    animation:
      ${fadeOut} 0.2s ease-out,
      ${scaleOut} 0.2s ease-out;
  }

  ${(p) =>
    !p.disableMobileFullscreen
      ? css`
          ${mediaMobile} {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: none;
            border-radius: 0;
            box-shadow: none;
            height: 100%;
            max-height: 100%;

            --modal-transform-x: 0;
            --modal-transform-y: 0;
          }
        `
      : css`
          padding-top: 0;
          padding-bottom: 0;
        `}

  ${({
    width,
    height,
    gap,
    dynamicWidth = false,
    dynamicHeight = true,
    disableMobileFullscreen,
  }) => css`
    max-width: ${modalWidths[width ?? 'md']};
    max-height: ${modalHeights[height ?? 'md']};
    gap: ${modalContentGaps[gap ?? 'xs']};

    width: ${dynamicWidth ? 'max-content' : '100%'};
    height: ${dynamicHeight ? 'max-content' : '100%'};

    ${!disableMobileFullscreen
      ? css`
          ${mediaMobile} {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
          }
        `
      : css`
          ${mediaMobile} {
            max-width: calc(100dvw - 2rem);
            border-radius: 2rem;
          }
        `}
  `}

    ${(p) =>
      !p.disableMobileFullscreen &&
      p.respectMobileKeyboard &&
      css`
        :not(html[data-viewport='true']) & {
          ${mediaMobile} {
            height: calc(100% - var(--keyboard-height, 0px));
            padding-bottom: calc(
              env(safe-area-inset-bottom) - clamp(
                  0px,
                  env(safe-area-inset-bottom),
                  var(--keyboard-height, 0px)
                )
            );
          }
        }

        ${handleViewportCSS}
      `}
`;

interface ModalCloseProps {
  noAutoMargin?: boolean;
}

const ModalClose = styled(Button)
  .attrs((props) => {
    return {
      variant: 'bg-secondary',
      shape: 'square',
      size: 'sm',
      autoFocus: false,
      as: DialogPrimitive.Close,
      ...props,
      children: (
        <>
          <CloseIcon size={15} />
          <span className="sr-only">Close</span>
        </>
      ),
    };
  })
  .withConfig(preventForwardPropsConfig(['noAutoMargin']))<ModalCloseProps>`
  margin-left: ${({ noAutoMargin }) => (noAutoMargin ? 'unset' : 'auto')};
`;

const ModalIconButton = styled(Button).attrs((props) => {
  return {
    variant: 'bg-secondary',
    shape: 'square',
    size: 'sm',
    autoFocus: false,
    ...props,
  };
})`
  margin-left: auto;
`;

const MobileBackButton = styled(Button).attrs((props) => {
  return {
    variant: 'transparent',
    size: 'xs',
    autoFocus: false,
    ...props,
    as: DialogPrimitive.Close,
    children: (
      <>
        <BackIcon width={18} height={18} />
      </>
    ),
  };
})`
  color: ${cssVar['color-text-primary']};
  display: none;

  ${mediaMobile} {
    display: flex;
  }
`;

interface ModalBaseHeaderProps {
  padding?: 'sm' | 'default';
  variant?: 'simple' | 'default';
  mobileShadowOverContent?: boolean;
  mobileOnly?: boolean;
}

const cssModalBaseHeaderPadding = css<ModalBaseHeaderProps>`
  ${mediaNotMobile} {
    ${(p) => {
      switch (p.padding) {
        case 'sm':
          return css`
            padding: 13px 13px 13px 16px;
          `;

        case 'default':
        default:
          return css`
            padding: 16px 20px;
          `;
      }
    }}
  }

  ${mediaMobile} {
    padding: 16px 20px;
  }
`;

const cssModalBaseHeaderVariant = css<ModalBaseHeaderProps>`
  ${(p) => {
    switch (p.variant) {
      case 'simple':
        return css`
          border-bottom: none;
          padding-bottom: 0;
        `;

      case 'default':
      default:
        return css`
          border-bottom: 1px solid ${cssVar['color-border-primary']};
        `;
    }
  }}
`;

const ModalBaseHeader = styled.div<ModalBaseHeaderProps>`
  display: flex;
  gap: 1.5rem;
  text-align: left;
  min-height: 42px;
  align-items: center;
  flex-shrink: 0;

  ${cssModalBaseHeaderPadding}
  ${cssModalBaseHeaderVariant}

  ${(p) =>
    p.mobileShadowOverContent &&
    css`
      ${mediaMobile} {
        box-shadow:
          0px 4px 4px 0px rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.02),
          0px 4px 25px 0px rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.05);
      }
    `}

  ${mediaNotMobile} {
    ${(p) =>
      p.mobileOnly &&
      css`
        display: none;
      `}
  }
`;

const ModalHeader = styled(ModalBaseHeader).attrs((props) => {
  return {
    ...props,
    children: (
      <>
        <MobileBackButton />
        <ModalTitle>{props.children}</ModalTitle>
        <ModalClose />
      </>
    ),
  };
})``;

interface ModalFooterProps extends CssBackgroundProp {
  // Actions is for default actions like cancel and confirm, so buttons at the end of the modal/footer
  // Call-to-action is for some text + button at the end of the modal/footer (e.g. "Don't have an account? <Sign up>")
  variant?: 'actions' | 'fullscreen' | 'call-to-action';
  padding?: 'sm' | 'default';
  gap?: 'lg' | 'default';
  grow?: boolean;
}

const cssModalFooterProperties = css<ModalFooterProps>`
  ${(p) => {
    switch (p.variant) {
      case 'call-to-action':
        return css`
          justify-content: space-between;
          border-top: 1px solid ${cssVar['color-border-primary']};
          background-color: ${cssVar['color-bg-secondary']};
          padding: 16px 20px;
        `;
      case 'fullscreen':
        return css`
          justify-content: space-between;
          align-self: center;
          border-top: 1px solid ${cssVar['color-border-primary']};
          width: 100%;
          max-width: 350px;
          padding: ${p.padding === 'sm' ? '10px 0 16px 0' : '20px 0 20px 0'};

          ${mediaMobile} {
            padding: ${p.padding === 'sm' ? '10px 13px 16px 13px' : '20px 20px 20px 20px'};
          }
        `;
      case 'actions':
      default:
        return css`
          justify-content: flex-end;
          padding: ${p.padding === 'sm' ? '0 13px 16px 13px' : '10px 20px 20px 20px'};
        `;
    }
  }}
`;

const ModalFooter = styled.div<ModalFooterProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => (p.gap === 'lg' ? '16px' : '10px')};
  flex-grow: ${(p) => (p.grow ? 1 : 0)};

  ${cssModalFooterProperties}
  // cssModalFooterProperties specifies it's own background, 
  // getCssBackgroundrendering this below to allow override
  ${getCssBackground()}
`;

interface ModalTitleProps {
  /**
   * md-shared - same size for both mobile and desktop 20px
   */
  size?: 'xs' | 'sm' | 'default' | 'md-shared';
}

const cssModalTitleProperties = css<ModalTitleProps>`
  ${(p) => {
    switch (p.size) {
      case 'xs':
        return css`
          font-size: 16px;
          font-weight: 700;
          ${mediaMobile} {
            font-size: 20px;
            line-height: 24px;
            min-height: 0;
            font-weight: 600;
          }
        `;

      case 'sm':
        return css`
          font-size: 18px;
          font-weight: 400;
          min-height: 42px;
        `;
      case 'md-shared':
        return css`
          font-size: 20px;
          line-height: 24px;
          min-height: 0;
          font-weight: 600;
        `;

      case 'default':
      default:
        return css`
          font-size: 26px;
          font-weight: 700;
          min-height: 42px;

          ${mediaMobile} {
            font-size: 20px;
            line-height: 24px;
            min-height: 0;
            font-weight: 600;
          }
        `;
    }
  }}
`;

const ModalTitle = styled(DialogPrimitive.Title)<ModalTitleProps>`
  color: ${cssVar['color-text-primary']};
  font-style: normal;
  line-height: 24px;
  display: inline-flex;
  align-items: center;

  ${cssModalTitleProperties}
`;

const ModalDescription = styled(DialogPrimitive.Description)`
  font-size: 14px;
  font-weight: 400;
  color: ${cssVar['color-text-secondary']};
`;

interface ModalBodyProps extends CssBackgroundProp {
  padding?: 'sm' | 'default' | 'none';
  grow?: boolean;
}

const cssModalBodyPadding = css<ModalBodyProps>`
  ${mediaNotMobile} {
    ${(p) => {
      switch (p.padding) {
        case 'none':
          return css`
            padding: 0;
          `;
        case 'sm':
          return css`
            padding: 16px;
          `;
        case 'default':
        default:
          return css`
            padding: 20px;
            ${mediaMobile} {
              padding: 24px;
            }
          `;
      }
    }}
  }

  ${mediaMobile} {
    padding: 20px;
    ${mediaMobile} {
      padding: ${({ padding }) => (padding === 'none' ? '0px' : '20px')};
    }
  }
`;

const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 20px;
  gap: 30px;
  color: ${cssVar['color-text-primary']};
  flex-grow: ${(p) => (p.grow ? 1 : 0)};

  ${cssModalBodyPadding}
  ${getCssBackground('primary')}
`;

const ModalButton = styled(Button).attrs((props) => {
  return {
    variant: 'primary',
    ...props,
    size: undefined,
  };
})`
  font-weight: 700;
  font-size: 1rem;
  padding: 0 0.625rem;
  gap: 0.625rem;

  ${mediaMobile} {
    border-radius: 16px;
    height: 54px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding: 0 1.25rem;
  }
`;

const ModalCancelButton = styled(ModalButton).attrs((props) => {
  return {
    ...props,
    variant: 'bg-tertiary',
  };
})``;

const ModalDoubleButtonContainer = styled(Flex)`
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  ${mediaMobile} {
    gap: 0.625rem;
    & > * {
      flex-grow: 1;
      width: 1px;
    }
  }
`;

const ModalFloatingDesktopCloseButton = styled(Button).attrs((props) => {
  return {
    variant: 'bg-primary',
    autoFocus: false,
    as: DialogPrimitive.Close,
    ...props,
    children: (
      <>
        Cancel <Kbd>Esc</Kbd>
      </>
    ),
  };
})`
  border-radius: 20px;
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 127;
  background: rgba(${(cssVar['color-bg-primary-rgb'], 0.7)});
  ${mediaMobile} {
    display: none;
  }
`;

export default Object.assign(ModalRoot, {
  Trigger: ModalTrigger,
  Portal: ModalPortal,
  Overlay: ModalOverlay,
  Content: ModalContent,
  ContentRaw: DialogPrimitive.Content,
  Close: ModalClose,
  FloatingDesktopCloseButton: ModalFloatingDesktopCloseButton,
  Header: ModalHeader,
  BaseHeader: ModalBaseHeader,
  Footer: ModalFooter,
  Title: ModalTitle,
  Description: ModalDescription,
  Body: ModalBody,
  Button: ModalButton,
  IconButton: ModalIconButton,
  CancelButton: ModalCancelButton,
  DoubleButtonContainer: ModalDoubleButtonContainer,
});
