import { Webpage } from '@/src/modules/resources/resources.types';
import {
  FabricSearchHit,
  ImageContext,
  PageContext,
  PrivateTag,
  TextContext,
} from '@fabric/woody-client';
import { UserPublicInfo } from '@fabric/woody-client/src/types/user';
import { CommentWithAuthor } from '../modules/comments/comments.types';
import { Space } from '../modules/spaces/spaces.types';

export enum ResourceState {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const parseResourceState = (state: string): ResourceState => {
  return Object.values(ResourceState).includes(state as ResourceState)
    ? (state as ResourceState)
    : ResourceState.FAILED;
};

type StoredFileFDocData = {
  title: string;
  extension: string | null;
  contentType: string | null;
  contentLength: number | null;
  url: string;
  thumbnail?: {
    sm: string;
    md: string;
    lg: string;
  };
};

type HalNotepad = {
  type: 'notepad';
  title: string;
  content?: string | null;
  editorjs?: { content?: string | null } | null;
  isYjsEnabled: boolean;
  modifiedAt: Date;
  createdAt: Date;
};

type Webnote = {
  pageUrl: string;
  pageTitle: string;
  statePreview: ResourceState;
  webpage?: Webpage | null;
};

export type Thumbnail = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export type User = Omit<UserPublicInfo, 'modifiedAt' | 'createdAt' | '_links'>;

export type Ancestor = {
  id: string;
  name: string;
};

// Experimental - idea is to potentially show status of things on the expanded view
export interface FdocMeta {
  isDeleting?: boolean;
  isUpdating?: boolean;
  isMoving?: boolean;

  placeholderImage?: string;
}

export interface BaseFdoc {
  id: string;

  parentResourceId: string | null;
  isDirectShared: boolean;
  isShared: boolean;
  stateProcessing: ResourceState;
  commentCount: number | null;
  commentPinned: CommentWithAuthor | null;
  commenters: FabricSearchHit['commenters'];
  label: number | null;
  list: string | null;
  groupKey?: string;
  vectorDistance?: number;
  listData?: Space;

  personal: {
    tags: PrivateTag[];
  };

  originUrl: string | null;

  createdAt: string;
  modifiedAt: string;

  user: User | null;

  _meta?: FdocMeta;
  thumbnail?: Thumbnail | null;
  fileUrl?: string | null;
}

export interface PageFdoc extends BaseFdoc {
  type: 'page';
  data: Webnote &
    PageContext & {
      screenshotUrl?: string;
      textUrl?: string;
    };
  isWebnote: true;
}

export interface ImageFdoc extends BaseFdoc {
  type: 'image';
  data: Webnote & ImageContext;
  isWebnote: true;
  thumbnail?: Thumbnail | null;
}

export interface TextFdoc extends BaseFdoc {
  type: 'text';
  data: Webnote & TextContext;
  isWebnote: true;
}

export interface NotepadFdoc extends BaseFdoc {
  type: 'notepad';
  data: Pick<
    HalNotepad,
    'content' | 'title' | 'editorjs' | 'isYjsEnabled' | 'modifiedAt' | 'createdAt'
  >;
  isWebnote: false;
}

export interface StoredFileFdoc extends BaseFdoc {
  type: 'stored_file';
  data: Omit<StoredFileFDocData, 'thumbnail'> & {
    thumbnail: Thumbnail | null;
  };
  isWebnote: false;
  key?: string;
}

export interface FolderFdoc extends BaseFdoc {
  type: 'folder';
  data: {
    name: string;
  };
  isWebnote: false;
}

export type Fdoc = PageFdoc | ImageFdoc | TextFdoc | NotepadFdoc | StoredFileFdoc | FolderFdoc;

export const getThumbnail = (
  thumbnail?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  } | null,
) => {
  return thumbnail && thumbnail.sm && thumbnail.md && thumbnail.lg && thumbnail.xl
    ? {
        sm: thumbnail.sm,
        md: thumbnail.md,
        lg: thumbnail.lg,
        xl: thumbnail.xl,
      }
    : null;
};

export enum CategoryToFamily {
  All = 'all', // not used, just to keep typescript happy
  Highlights = 'highlight',
  Images = 'image',
  Bookmarks = 'page',
  Files = 'generic_file',
  Notes = 'notepad',
}

/**
 * @deprecated
 */
export type FdocOfType<T extends Fdoc['type']> = Fdoc & { type: T };
