export const CODE_KEY_MAP: Record<string, string> = {
  backquote: '`',
  bracketright: ']',
  bracketleft: '[',
  backslash: '\\',
  semicolon: ';',
  quote: "'",
  comma: ',',
  period: '.',
  slash: '/',
  equal: '=',
  minus: '-',
  tab: 'tab',
  space: 'space',
  enter: 'enter',
  escape: 'esc',
  delete: 'del',
  arrowup: '↑',
  arrowdown: '↓',
  arrowleft: '←',
  arrowright: '→',
  home: 'home',
  end: 'end',
  pageup: 'pageup',
  pagedown: 'pagedown',
  printscreen: 'print',
  scrolllock: 'scroll',
  pause: 'pause',
  insert: 'ins',
  numlock: 'num',
  capslock: 'caps',

  // TODO
};
