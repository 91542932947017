import { QueryParams } from '@/src/hooks/useQueryParams';
import { CommentWithAuthor } from '@/src/modules/comments/comments.types';
import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { Fdoc, FdocMeta } from '@/src/types/api';
import {
  FilteredResources,
  ResourceFilterSort,
  ResourceFilters,
  components,
  paths,
} from '@fabric/woody-client';
import { WithRequired } from '@tanstack/react-query';

export type QueryResourceListParams = WithRequired<
  paths['/v2/resources/filter']['post'],
  'requestBody'
>['requestBody']['content']['application/json'];

export type ResourceFilterOptions = {
  filters?: ResourceFilters;
  /**
   * @deprecated use filtersV2
   */
  filtersV2?: QueryResourceListParams;
  sort?: ResourceFilterSort;
  perPage?: number;
};

export type FilteredFdocs = Omit<FilteredResources, 'results'> & {
  results: Fdoc[];

  _meta: {
    options: ResourceFilterOptions;
  };
};

export interface ResourceQueryParams extends QueryParams {
  sort: ResourceFilterSort['field'];
  order: ResourceFilterSort['order'];
  family: ResourceFilters['family'];
}

export type DefaultResourceFilterOptions = {
  sort: ResourceFilterSort;
  /**
   * @deprecated use filtersV2
   */
  filters?: ResourceFilters;
  filtersV2?: QueryResourceListParams;
  perPage: number;
};

export type ApiResourceRootSubtype = ResourceRoot['subtype'];

/******************************************************************************************************************
 * Resource detail types
 *
 * incorrectly generated from zod -> openapi -> type gen, e.g.
 * components['schemas']['ResourceDetailDocument'] typings don't contain all the data
 *
 * we recreate the typings here manually from the spec of the properties
 *
 * root - incorrect infer of the subtype - resulting in unknown
 * kind - removing from the base completely - incorrect infer
 *
 */
export type ResourceDetailBase = Omit<
  components['schemas']['Resource'],
  'kind' | 'label' | 'commentPinned' | 'root'
> & {
  label: components['schemas']['ColorLabel'] | null;
  commentPinned: CommentWithAuthor | null;
  root?: {
    id: ResourceRoot['id'];
    type: ResourceRoot['type'];
    subtype: ApiResourceRootSubtype;
    folder: components['schemas']['ResourceRootFolder'];
  } | null;
  _meta?: FdocMeta;
};

interface ResourceDetailExt<K extends components['schemas']['Resource']['kind']>
  extends ResourceDetailBase {
  kind: K;
}

export interface ResourceDetailDocument extends ResourceDetailExt<'document'> {
  data: components['schemas']['DocumentMetadata'];
}

export type Webpage = Omit<
  components['schemas']['Webpage'],
  'image' | 'favicon' | 'html' | 'reader' | 'screenshot' | 'metadata'
> & {
  image: components['schemas']['PresignedImage'] | null;
  favicon: components['schemas']['PresignedImage'] | null;
  html: components['schemas']['PresignedFile'] | null;
  reader: components['schemas']['PresignedFile'] | null;
  screenshot: components['schemas']['PresignedImage'] | null;
  metadata?: components['schemas']['SpotifyMetadata'] | Record<string, unknown> | null;
};

export interface ResourceDetailBookmark extends ResourceDetailExt<'bookmark'> {
  data: Omit<components['schemas']['BookmarkMetadata'], 'webpage'> & {
    webpage: Webpage | null;
  };
}
export interface ResourceDetailFolder extends ResourceDetailExt<'folder'> {
  data: components['schemas']['FolderMetadata'];
}
export interface ResourceDetailHighlight extends ResourceDetailExt<'highlight'> {
  data: components['schemas']['HighlightMetadata'];
}
export interface ResourceDetailImage extends ResourceDetailExt<'image'> {
  data: Omit<components['schemas']['ImageMetadata'], 'webpage'> & {
    webpage: null | components['schemas']['Webpage'];
  };
}

export interface ResourceDetailNotepad extends ResourceDetailExt<'notepad'> {
  data: components['schemas']['NotepadMetadata'];
}

export interface ResourceDetailDefault extends ResourceDetailExt<'default'> {
  data: components['schemas']['DefaultMetadata'];
}

export interface ResourceDetailVideo extends ResourceDetailExt<'video'> {
  data: components['schemas']['VideoMetadata'];
}

export interface ResourceDetailAudio extends ResourceDetailExt<'audio'> {
  data: components['schemas']['AudioMetadata'];
}

export type ResourceDetail =
  | ResourceDetailDocument
  | ResourceDetailBookmark
  | ResourceDetailFolder
  | ResourceDetailHighlight
  | ResourceDetailImage
  | ResourceDetailNotepad
  | ResourceDetailDefault
  | ResourceDetailVideo
  | ResourceDetailAudio;

/******************************************************************************************************************/

export enum FabricMimeTypes {
  VND_FABRIC_FOLDER = 'application/vnd.fabric.folder',
  VND_FABRIC_NOTEPAD = 'application/vnd.fabric.notepad',
  VND_FABRIC_BOOKMARK = 'application/vnd.fabric.bookmark',
  VND_FABRIC_HIGHLIGHT = 'application/vnd.fabric.highlight',
}

/******************************************************************************************************************
 * Resource context search
 */

export interface QueryResourceList_PageBase {
  total: number;
  hasMore: boolean;
  resources: (ResourceDetail & { score: number })[];
}

export type QueryResourceListContextSearchParams = WithRequired<
  paths['/v2/search']['post'],
  'requestBody'
>['requestBody']['content']['application/json'];

export type QueryResourceListContextSearchParams_Filters =
  QueryResourceListContextSearchParams['filters'];

export interface ResourceParent {
  id: string;
  name: string | null;
}
