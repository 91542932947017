export type Position = {
  x: number;
  y: number;
};

export const hasMacOSKbd = () => {
  return typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

export const isWindowsPlatform = () => {
  return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Windows') > -1;
};

export type Size = {
  width: number;
  height: number;
};

export type Coordinate = {
  latitude: number;
  longitude: number;
};
