import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { keyToDisplayValue } from '@/src/modules/keyboardShortcuts/modifierKeys';
import { RecordedHotkey } from '@/src/modules/keyboardShortcuts/types';
import { useMemo } from 'react';
import { create } from 'zustand';

type KeyboardLayoutMap = Map<string, string>;

export const useKeyboardMapStore = create<{
  codeToKeyMap: Map<string, string>;
  keyToCodeMap: Map<string, string>;
  isLoaded: boolean;
  isError: boolean;
  setKeyboardMap: (keyboardMap: Map<string, string>) => void;
  setLoadError: () => void;
}>((set) => ({
  codeToKeyMap: new Map(),
  keyToCodeMap: new Map(),
  isLoaded: false,
  isError: false,
  setKeyboardMap: (keyboardMap: Map<string, string>) => {
    const keyToCodeMap = new Map<string, string>();

    Array.from(keyboardMap).forEach(([code, key]) => {
      keyToCodeMap.set(key.toLowerCase(), code);
    });

    set({ codeToKeyMap: keyboardMap, keyToCodeMap, isLoaded: true });
  },
  setLoadError: () => {
    set({ isError: true, isLoaded: false });
  },
}));

export const useKeyboardMapChangeListener = () => {
  const { setKeyboardMap, setLoadError } = useKeyboardMapStore((state) => ({
    setKeyboardMap: state.setKeyboardMap,
    setLoadError: state.setLoadError,
  }));
  useClientLayoutEffect(() => {
    if (typeof navigator !== 'undefined' && 'keyboard' in navigator) {
      try {
        /**
         * not fully supported API in all browsers
         * also seems to be blocked by brave adblocker?!
         * https://discord.com/channels/934045073002364981/1121775392496287865/1298626431928111218
         */
        (navigator.keyboard as any).getLayoutMap().then((keyboardLayoutMap: KeyboardLayoutMap) => {
          setKeyboardMap(keyboardLayoutMap);
        });
      } catch (e) {
        setLoadError();
      }
    } else {
      setLoadError();
    }
  }, []);
};

const codeOrderValue: Record<string, number> = {
  Option: 7,
  Alt: 7,
  Control: 5,
  Meta: 10,
  Shift: 4,
};

const getOrderValue = (code: string) => {
  return codeOrderValue[code] || 0;
};

const sortRecordedHotkeys = (data: string[]) => {
  return data.sort((a, b) => {
    return getOrderValue(b) - getOrderValue(a);
  });
};

/**
 * returns a mapper function if navigator.keyboard.getLayoutMap() is available
 */
export const useRecordedHotkeysToActualLayoutMapper = () => {
  const keyboardMap = useKeyboardMapStore((state) => ({
    keyToCode: state.keyToCodeMap,
    codeToKey: state.codeToKeyMap,
  }));

  const handler = useMemo(
    () =>
      !keyboardMap
        ? undefined
        : (recordedHotkeys: RecordedHotkey[]) => {
            const map = keyboardMap.codeToKey;

            const keys = sortRecordedHotkeys(
              recordedHotkeys.map((item) => {
                const value = item.value;
                return map.get(value) || value;
              }),
            );
            const keysDisplayed = keys.map(keyToDisplayValue);
            const keysDisplayedStr = keysDisplayed.join(' + ');
            return {
              isMapped: true,
              keys,
              keysDisplayed,
              keysDisplayedStr,
            };
          },
    [keyboardMap],
  );
  return handler;
};
