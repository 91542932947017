import {
  desktopFloatUrls,
  isInDesktop,
  openDesktopGlobalSearch,
  openQuickNoteWindow,
} from '@/src/hooks/todesktop';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import { createLogger } from '@/src/lib/logger/createLogger';
import { recordedHotkeysToAccelerator } from '@/src/modules/desktop/shortcuts/recordedHotkeysToAccelerator';
import { useDefaultAccelerators } from '@/src/modules/desktop/shortcuts/useDefaultAccelerators';
import { useRecordedHotkeysToActualLayoutMapper } from '@/src/modules/keyboardShortcuts/hooks/useKeyboardMap';
import { useQueryUserPreferences } from '@/src/modules/user/queries/useQueryUserPreferences';
import { UserKeyboardShortcutsDesktop } from '@/src/modules/user/user.types';
import { hasMacOSKbd } from '@/src/types/global';
import inNextServer from '@/src/utils/next';
import { useMutation } from '@tanstack/react-query';
import { globalShortcut } from '@todesktop/client-core';
import React from 'react';

const logger = createLogger('Desktop shortcuts', true);

if (isInDesktop() && !desktopFloatUrls.includes(window.location.pathname) && !inNextServer()) {
  globalShortcut.unregisterAll();
}

const registerShortcut = async (accelerator: string, cb: VoidFunction) => {
  if (accelerator === '') {
    return;
  }

  try {
    logger.log('(', accelerator, ')', 'Checking if registered');
    const isRegistered = await globalShortcut.isRegistered(accelerator);

    if (isRegistered) {
      logger.log('(', accelerator, ')', 'Unregistering');
      await globalShortcut.unregister(accelerator);
    }
    await globalShortcut.register(accelerator, cb);
    logger.log('(', accelerator, ')', 'Registering');

    return true;
  } catch (e) {
    logger.error('Registering error', e);
    return false;
  }
};

const callbacks: Record<keyof UserKeyboardShortcutsDesktop, VoidFunction> = {
  quickNote: openQuickNoteWindow,
  quickSearch: openDesktopGlobalSearch,
};

export const useDesktopAppShortcuts = (options?: { runOnce?: boolean }) => {
  const runOnce = options?.runOnce ?? false;
  const { data } = useQueryUserPreferences();
  const mapper = useRecordedHotkeysToActualLayoutMapper();
  const defaultAccelerators = useDefaultAccelerators();

  const isDesktopShortcutEnabled =
    isInDesktop() &&
    !desktopFloatUrls.includes(window.location.pathname) &&
    !inNextServer() &&
    !!mapper &&
    /**
     * do not enable when user preferences are not loaded
     */
    !!data;

  const shortcuts =
    data?.frontend?.[hasMacOSKbd() ? 'macOsDesktopAppShortcuts' : 'desktopAppShortcuts'];

  /**
   * put together user config and default accelerators
   */
  const accelerators = React.useMemo(() => {
    const res = Object.entries(defaultAccelerators).reduce(
      (acc, [k, value]) => {
        const key = k as keyof UserKeyboardShortcutsDesktop;

        const shortcutConfig = shortcuts?.[key];

        if (shortcutConfig?.disabled) {
          return acc;
        }

        return {
          ...acc,
          [key]:
            shortcutConfig?.recordedKeys && mapper
              ? recordedHotkeysToAccelerator(shortcutConfig.recordedKeys)
              : value.accelerator,
        };
      },
      {} as Record<keyof UserKeyboardShortcutsDesktop, string>,
    );

    return res;
  }, [shortcuts, mapper, defaultAccelerators]);

  const mutationApplyShortcuts = useMutation({
    mutationFn: async () => {
      if (!isDesktopShortcutEnabled) {
        return;
      }

      logger.log('Re-applying shortcuts');

      await globalShortcut.unregisterAll();
      logger.log('Previously set shortcuts unregistered');
      return Promise.allSettled(
        Object.entries(accelerators).map(async ([key, accelerator]) => {
          return registerShortcut(
            accelerator,
            callbacks[key as keyof UserKeyboardShortcutsDesktop],
          );
        }),
      );
    },
    onSuccess: () => {
      logger.log('Shortcuts applied');
    },
  });

  /**
   *
   */
  useRunOnce(mutationApplyShortcuts.mutate, isDesktopShortcutEnabled && runOnce);

  return mutationApplyShortcuts.mutateAsync;
};
