export type MobileOS = 'iOS' | 'Android' | 'Unknown';

export enum ShareIntentType {
  URL = 'url',
  TEXT = 'text',
  FILE = 'file',
}

export interface RawShareIntent {
  type: ShareIntentType;
  comment?: string;
  [key: string]: unknown;
}

export interface ShareIntent {
  type: ShareIntentType;
  comment: string;
}

export interface UrlShareIntent extends ShareIntent {
  type: ShareIntentType.URL;
  url: string;
  title: string;
  description: string;
  imageUrl: string;
}

export interface TextShareIntent extends ShareIntent {
  type: ShareIntentType.TEXT;
  text: string;
}

export interface FileShareIntent extends ShareIntent {
  type: ShareIntentType.FILE;
  fileUrl: string;
  fileName: string;
  fileSize: number;
  fileLastModified?: string | number;
  thumbnail?: string;
  fileType: string;
}
