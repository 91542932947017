export const isNextDev = process.env.NODE_ENV === 'development';
export const isFabricDev = process.env.REACT_APP_ENV === 'develop';
export const isFabricProd = process.env.REACT_APP_ENV === 'production';

export const CURRENT_URL =
  typeof window !== 'undefined'
    ? window.location.origin
    : process.env.NEXT_PUBLIC_WEBSITE_URL ??
      (process.env.NEXT_PUBLIC_VERCEL_URL
        ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
        : 'http://localhost:3000');

export const isDebugEnabled =
  process.env.REACT_APP_DEBUG === 'true' || process.env.NEXT_PUBLIC_APP_DEBUG === 'true';

export const envLinks = {
  desktopAppDownloadUrl:
    process.env.NEXT_PUBLIC_DESKTOP_APP_DOWNLOAD_URL || 'https://dl.todesktop.com/220930m1ahpjvoh',
};
