import { CURRENT_URL } from '@/src/constants/env';
import { isInMobile } from '@/src/hooks/mobile';
import { useAnalyticsStore } from '@/src/modules/analytics/analytics.store';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { authApi, OAuthTypes } from '@/src/modules/auth/api/auth.api';
import { useAuthErrorHandler } from '@/src/modules/auth/hooks/useAuthErrorHandler';
import { ApiUserMe } from '@/src/modules/user/user.types';
import { Browser } from '@capacitor/browser';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { MOBILE_AUTH_DEEPLINK } from '../lib/mobile/oauth';
import { SentryUser } from '../lib/sentry/sentryUser';
import { omit, pick } from '../lib/store';
import { oneOfGroup } from '../modules/user/utils/group';
import { getWoodyClient, useWoody } from '../services/woody/woody';
import { removeAlert, warning } from '../store/alerts';
import { NextPageAuthRequirements } from '../types/page';
import { deleteAllCookies } from '../utils/cookies';
import { resetStores } from './resetStores';
import { useClientLayoutEffect } from './useClientLayoutEffect';

type AuthStatus = 'unauthenticated' | 'authenticated' | 'loading';

declare global {
  interface Window {
    loggedIn?: boolean;
    authStatus?: AuthStatus;
  }
}

type Status = {
  loggedIn: boolean;
  authStatus: AuthStatus;
  user: ApiUserMe | null;
};

type AuthEvents = {
  login: ApiUserMe;
  user: ApiUserMe | null;
  logout: undefined;
  status: Status;
};

type EventListener<T> = T extends undefined ? () => void : (arg: T) => void;

interface AuthStore {
  authStatus: AuthStatus;
  user: ApiUserMe | null;
  isLoggedIn: boolean;
  isRedirecting: boolean;

  listeners: {
    [K in keyof AuthEvents]?: EventListener<AuthEvents[K]>[];
  };

  on: <K extends keyof AuthEvents>(event: K, listener: EventListener<AuthEvents[K]>) => () => void;
  emit: <K extends keyof AuthEvents>(event: K, arg: AuthEvents[K]) => void;

  login: (email: string, password: string) => Promise<ApiUserMe>;
  handleOAuthCallback: (options: {
    provider: OAuthTypes;
    code: string;
    mobile?: boolean;
  }) => Promise<ApiUserMe>;
  logout: () => Promise<void>;

  // Modifying state functions
  setAuthStatus: (status: AuthStatus) => void;
  setUser: (user: ApiUserMe) => void;
  setIsLoggedIn: (isLoggedIn: boolean, status?: AuthStatus) => void;
  setIsRedirecting: (isRedirecting: boolean) => void;
  getUser: () => Promise<ApiUserMe>;
  signUpEmail: string | null;
  setSignUpEmail: (email: string | null) => void;
}

const clearCache = () => mutate(() => true, undefined, { revalidate: false });

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      authStatus: 'loading' as const,
      signUpEmail: null,
      setSignUpEmail: (email) => set({ signUpEmail: email }),
      user: null,
      roots: [],
      isLoggedIn: false,
      isRedirecting: false,

      listeners: {},

      on: (event, listener) => {
        set((state) => {
          const listenersForEvent = state.listeners[event] || [];
          return {
            ...state,
            listeners: {
              ...state.listeners,
              [event]: [...listenersForEvent, listener],
            },
          };
        });

        // Return the unsubscribe function
        return () => {
          set((state) => {
            const updatedListeners = state.listeners[event]?.filter((l) => l !== listener) || [];
            return {
              ...state,
              listeners: {
                ...state.listeners,
                [event]: updatedListeners,
              },
            };
          });
        };
      },

      emit: (event, arg) => {
        const state = get();
        const listenersForEvent = state.listeners[event];
        if (listenersForEvent) {
          for (const listener of listenersForEvent) {
            listener(arg as never);
          }
        }
      },
      getUser: async () => {
        const client = getWoodyClient();

        const userResponse = await client.v2('/v2/user/me');

        try {
          SentryUser.set(userResponse);

          set({
            authStatus: 'authenticated',
            isLoggedIn: true,
            user: userResponse,
            isRedirecting: false,
          });

          get().emit('status', {
            authStatus: 'authenticated',
            user: userResponse,
            loggedIn: true,
          });

          get().emit('user', userResponse);

          await mutate('woody-roots');
          await mutate('woody-user', userResponse, false);

          const { identify, track } = useAnalyticsStore.getState();
          identify(userResponse);
          track(AnalyticsEvents.EmailLogin, { userId: userResponse.id });

          return userResponse;
        } catch (e) {
          SentryUser.clear();
          throw e;
        }
      },
      login: async (email, password) => {
        const client = getWoodyClient();

        await client.v2('/v2/authentication/login', {
          method: 'post',
          headers: {
            'x-csrf-token': await authApi.getCSFRToken(),
          },
          body: {
            email,
            password,
          },
        });

        await resetStores();
        await clearCache();

        set({
          isLoggedIn: true,
          authStatus: 'authenticated',
          isRedirecting: false,
        });

        const userResponse = await get().getUser();
        get().emit('login', userResponse);
        return userResponse;
      },
      handleOAuthCallback: async ({ provider, code, mobile }) => {
        const client = getWoodyClient();

        const callbackUri = mobile ? `${MOBILE_AUTH_DEEPLINK}://oauth` : `${CURRENT_URL}/oauth`;

        const { operation } = await client.v2(
          {
            endpoint: '/v2/authentication/oauth/{provider}/callback',
            params: {
              provider,
            },
          },
          {
            method: 'post',
            body: {
              code,
              redirectUri: callbackUri,
            },
          },
        );

        await resetStores();
        await clearCache();

        set({
          isLoggedIn: true,
          authStatus: 'authenticated',
          isRedirecting: false,
        });

        const userResponse = await get().getUser();

        if (operation === 'signup') {
          const { identify, track } = useAnalyticsStore.getState();
          identify(userResponse);
          track(AnalyticsEvents.Signup, {
            userId: userResponse.id,
            email: userResponse.email,
            name: userResponse.name,
            oauth: true,
          });
        }

        get().emit('login', userResponse);
        return userResponse;
      },

      logout: async () => {
        const client = getWoodyClient();
        const user = get().user;

        // remove the or-uuid local storage key
        localStorage.removeItem('or-uuid');

        set({
          authStatus: 'unauthenticated',
          user: null,
          isLoggedIn: false,
        });
        get().emit('logout', undefined);

        mutate('woody-user', null, false);

        await client.v2('/v2/authentication/logout', {
          method: 'delete',
        });

        clearCache();

        await resetStores();

        const { identify, track } = useAnalyticsStore.getState();
        identify(user);
        track(AnalyticsEvents.Logout, { userId: user?.id });

        deleteAllCookies();
        SentryUser.clear();
      },

      setAuthStatus: (status) => {
        set({ authStatus: status });
        get().emit('status', {
          authStatus: status,
          user: get().user,
          loggedIn: get().isLoggedIn,
        });
      },
      setUser: (user) => {
        set({ user, isLoggedIn: true, authStatus: 'authenticated', isRedirecting: false });
        get().emit('user', user);
      },
      setIsLoggedIn: (isLoggedIn, authStatus) => {
        set({ isLoggedIn, authStatus });
        get().emit('status', {
          authStatus: get().authStatus,
          user: get().user,
          loggedIn: isLoggedIn,
        });
      },
      setIsRedirecting: (isRedirecting) => set({ isRedirecting }),
    }),
    {
      name: 'fabric-auth-storage',
      version: 2.1,

      partialize: (state) => {
        // Access token is not stored in localStorage for security reasons
        // Auth status is not stored to make sure we always get the latest status
        const storeable = omit(state, ['authStatus', 'isRedirecting', 'listeners', 'isLoggedIn']);

        return {
          authStatus: 'loading',
          ...storeable,
        };
      },
    },
  ),
);

export const useAuthInitializer = (authRequirements: NextPageAuthRequirements) => {
  const router = useRouter();
  const { client } = useWoody();
  const {
    user: storedUser,
    setAuthStatus,
    setUser,
    setIsLoggedIn,
    authStatus,
    isLoggedIn,
    isRedirecting,
    setIsRedirecting,
    logout,
  } = useAuthStore(
    (state) =>
      pick(state, [
        'user',
        'setAuthStatus',
        'setUser',
        'setIsLoggedIn',
        'isLoggedIn',
        'isRedirecting',
        'setIsRedirecting',
        'authStatus',
        'logout',
      ]),
    shallow,
  );

  const [offline, setOffline] = useState(false);
  useEffect(() => {
    const handleOffline = () => setOffline(true);
    const handleOnline = () => setOffline(false);

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    if (!offline) return;

    const alert = warning({
      content: (
        <p>
          You are currently offline.{' '}
          <a
            href="#"
            style={{
              color: 'var(--color-primary)',
              textDecoration: 'underline',
            }}
            onClick={() => window.location.reload()}
          >
            Click here to refresh.
          </a>
        </p>
      ),
      disableTimeout: true,
    });

    return () => {
      alert && removeAlert(alert.id);
    };
  }, [offline]);

  const [retrying, setRetrying] = useState(true);
  const { data: user, isLoading } = useSWR(
    authStatus !== 'unauthenticated' || retrying ? 'woody-user' : null,
    async () => {
      if (authStatus !== 'authenticated') {
        setAuthStatus('loading');
      }

      const res = client.v2('/v2/user/me');
      return res;
    },
    {
      // Since most data is cached it should be fine to be a bit higher than 100ms
      dedupingInterval: 400,
      // Make sure we only re-check at a good interval, since this will do a backend request
      refreshInterval: 1000 * 60 * 5, // 5 minutes
      focusThrottleInterval: 1000 * 60 * 2, // 2 minutes,
      keepPreviousData: true,
      fallbackData: storedUser ?? undefined,
      errorRetryCount: 6,
      errorRetryInterval: 1000,
      shouldRetryOnError: true,
      revalidateOnReconnect: true,
      onSuccess: (data) => {
        SentryUser.set(data);
        if (data) {
          /**
           * set user automatically updates auth status and other properties
           */
          setUser(data);
          setRetrying(false);
        }
      },
      onErrorRetry: async (error, _key, _config, revalidate, { retryCount }) => {
        SentryUser.clear();
        setRetrying(true);

        // If no user stored and we get a 401 we assume the user is logged out and make sure the log out is recognized
        if (
          error.status === 401 &&
          error.detail.includes('Must be logged in to perform this action') &&
          (!user || retryCount > 2)
        ) {
          setAuthStatus('unauthenticated');
          setIsLoggedIn(false);
          setRetrying(false);
          return;
        }

        if (retryCount > 5) {
          setAuthStatus('unauthenticated');
          setIsLoggedIn(false);
          setRetrying(false);
          return;
        }

        setTimeout(
          () => revalidate({ retryCount, dedupe: true }),
          retryCount > 2 ? (retryCount - 2) * 1000 * 60 : retryCount * 1000,
        );
      },
    },
  );

  useEffect(() => {
    window.loggedIn = isLoggedIn;
    window.authStatus = authStatus;
  }, [isLoggedIn, authStatus]);

  const authStatusRef = useRef(authStatus);
  useEffect(() => {
    authStatusRef.current = authStatus;
  }, [authStatus]);

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  useClientLayoutEffect(() => {
    if (authStatus !== 'unauthenticated' && user && isLoggedIn) {
      if (isLoggingOut) {
        setIsRedirecting(false);
        setIsLoggingOut(false);
      }
      return;
    }

    if (
      !authRequirements.requiresAuth ||
      isRedirecting ||
      isLoading ||
      authStatus === 'loading' ||
      retrying ||
      offline
    )
      return;

    if (router.pathname === '/signin') return;

    logout();

    router.push('/signin');
    setIsRedirecting(true);
    setIsLoggingOut(true);
  }, [
    authStatus,
    isLoading,
    isLoggedIn,
    isLoggingOut,
    isRedirecting,
    setIsRedirecting,
    logout,
    offline,
    authRequirements.requiresAuth,
    retrying,
    router,
    user,
  ]);

  useClientLayoutEffect(() => {
    if (
      isRedirecting ||
      !user ||
      !authRequirements.requiredGroups ||
      (authRequirements.requiredGroups && oneOfGroup(user, authRequirements.requiredGroups))
    )
      return;

    setIsRedirecting(true);
    router.replace('/404').then(() => {
      setIsRedirecting(false);
    });
  }, [setIsRedirecting, user, authRequirements.requiredGroups, authStatus, isRedirecting, router]);

  useAuthErrorHandler(client, logout);
};

export const useAuthUser = () => useAuthStore((state) => state.user, shallow);
export const useAuthIsLoggedIn = () => useAuthStore((state) => state.isLoggedIn, shallow);
export const useAuthStatus = () => useAuthStore((state) => state.authStatus, shallow);

export const forceUserRefresh = async () => {
  return await mutate('woody-user');
};

const TUTORIAL_URL = 'https://fabric.so/app-welcome';
export const useRedirectAfterSuccessAuth = () => {
  const router = useRouter();
  const { client } = useWoody();

  return async (
    user: {
      onboarding: { isFrontendTutorialCompleted?: boolean | null | undefined };
    },
    options: {
      action: 'login' | 'confirm' | 'oauth';
      /**
       * will be passed to the captureException
       */
      extra?: Record<string, unknown>;
    },
  ) => {
    try {
      if (!user.onboarding.isFrontendTutorialCompleted) {
        /**
         * show the tutorial and update the user onboarding status
         */
        client.v2('/v2/user-onboarding', {
          method: 'patch',
          body: {
            isFrontendTutorialCompleted: true,
          },
        });
        if (isInMobile()) {
          Browser.open({
            url: TUTORIAL_URL,
            presentationStyle: 'popover',
            windowName: 'Fabric',
          });
          // go to / and clear the history

          router.push('/');
        } else {
          await router.push(TUTORIAL_URL);
        }
      } else {
        router.push('/');
      }
    } catch (e) {
      /**
       * Just a fail save if for some reason the redirects KO
       * this shouldn't happen but just to be sure
       */
      captureException(new Error('Failed to redirect after' + options.action), {
        extra: {
          error: e,
          action: options.action,
          ...options.extra,
        },
      });
      router.push('/');
    }
  };
};

export default useAuthStore;
