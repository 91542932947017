import { isFabricProd } from '@/src/constants/env';
import * as Sentry from '@sentry/nextjs';

export const sentryBaseConfig: Sentry.BrowserOptions | Sentry.NodeOptions | Sentry.EdgeOptions = {
  enabled: isFabricProd || !!process.env.SENTRY_FORCE_ENABLE,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: !!process.env.SENTRY_DEBUG_ENABLE,
  environment: process.env.REACT_APP_ENV,
  dsn: 'https://f740c94adadcf5875179454a0438bd6f@o4507161590956032.ingest.us.sentry.io/4507187023052800',

  /**
   * performance monitoring
   */
  enableTracing: false,
  tracesSampleRate: 1,

  integrations: [
    /**
     * capture console
     */
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    /**
     * replays
     * not in use, wére using open replay
     */
    // Sentry.replayIntegration({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
    Sentry.extraErrorDataIntegration({
      depth: 10,
      captureErrorCause: true,
    }),
  ],

  /**
   * replay sessions
   */
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
};
