import inNextServer from '@/src/utils/next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { fabricMobileAsync } from '../utils/fabricMobileAsync';

/**
 * This hook will send events to the native app with URL changes to make sure when in a sheet it stays in it's correct path.
 * This is used for the mobile app only.
 */
export const useFabricMobileSheet = () => {
  const router = useRouter();

  useEffect(() => {
    if (inNextServer() || !window.fabricMobile?.isSheet) return;

    fabricMobileAsync('onUrlChange', { url: window.location.href });

    router.beforePopState(({ url }) => {
      fabricMobileAsync('onUrlChange', { url });
      return true;
    });
  }, [router]);
};
