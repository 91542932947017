import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { QueryResourceList_PageBase, ResourceDetail } from '../resources.types';
import { isResourceDetailQueryKey, isResourceListQueryKey } from './queryKeyGuards';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const syncResourceDetailIntoResourceListQueries = (
  data: ResourceDetail,
  queryClient: QueryClient,
) => {
  queryClient.setQueriesData<InfiniteData<QueryResourceList_PageBase>>(
    {
      predicate: resourceQueryPredicates.resourceListAny,
      type: 'active',
    },
    (prevData) => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        pages: prevData.pages.map((page) => ({
          ...page,
          resources: page.resources.map((resource) => {
            if (resource.id === data.id) {
              return {
                ...resource,
                ...data,
              };
            }

            return resource;
          }),
        })),
      };
    },
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const syncFilterResourceQuery = (
  data: InfiniteData<QueryResourceList_PageBase>,
  queryClient: QueryClient,
) => {
  // Only updating the direct queries for now, since updating search queries and other filter queries seem like it would
  // potentially be quite complex and heavy on the client. And even cause a lot of unnecessary re-renders.
  for (const page of data.pages) {
    for (const resource of page.resources) {
      queryClient.setQueriesData<ResourceDetail>(
        {
          predicate: (q) => resourceQueryPredicates.resourceDetail(q, resource.id),
          type: 'active',
        },
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            ...data,
          };
        },
      );
    }
  }
};

export const syncResourceQuery = (data: unknown, queryKey: QueryKey, queryClient: QueryClient) => {
  if (!Array.isArray(queryKey) || queryKey.length === 0) return;

  if (isResourceDetailQueryKey(queryKey)) {
    syncResourceDetailIntoResourceListQueries(data as ResourceDetail, queryClient);
  }

  if (isResourceListQueryKey(queryKey)) {
    syncFilterResourceQuery(data as InfiniteData<QueryResourceList_PageBase>, queryClient);
  }

  // Will not care for search queries for now, since usually it has some missing data as it's stored in ES.
};
