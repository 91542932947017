import { ResourceDetail } from '@/src/modules/resources/resources.types';

export const mutateResourceMeta = (
  resource: ResourceDetail,
  meta: Partial<ResourceDetail['_meta']>,
): ResourceDetail => {
  return {
    ...resource,
    _meta: {
      ...resource._meta,
      ...meta,
    },
  };
};
